import { flatMap, get, has } from "lodash";
import { useReducer } from "react";
import toast from "react-hot-toast";
import api from "../../apis/axiosInterceptor";

interface Pages {
  pagesData: object;
  curPagesEditData: any;
  totalPageCount: number;
  totalData: number;
  error: string;
}

const initialArgs: Pages = {
  pagesData: {},
  curPagesEditData: {},
  totalPageCount: 0,
  totalData: 17,
  error: "",
};

const reducer = (state: Pages, action: Pages) => {
  return { ...state, ...action };
};

const usePages = () => {
  const [state, setState] = useReducer(reducer, initialArgs);

  const getPages = async (
    page: string,
    listPerPage: string,
    searchText: string,
  ) => {
    try {
      const result: any = await api.get(
        `/api/v1/pages?page=${page}&listPerPage=${listPerPage}&search=${searchText}`,
      );

      if (result.data.status) {
        setState({
          ...state,
          pagesData: result.data.data.data,
          totalPageCount: get(result, "data.data.totalPageCount", 0),
          totalData: get(result, "data.data.totalData", 0),
        });

        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr);
        setState({ ...state, error: errorStr });

        return false;
      }
    } catch (error: any) {
      toast.error(error.message);
      setState({ ...state, error: error.message });

      return false;
    }
  };

  const createPages = async (param: any) => {
    console.info("param => ", param);

    const paramObj = {
      ...param,
    };

    let createPagesToast;
    try {
      const result: any = await api.post("/api/v1/pages", paramObj, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (result.data.status) {
        createPagesToast = toast.success(result.data.message, {
          id: createPagesToast,
        });
        //toast.dismiss(createPagesToast);
        setState({ ...state, pagesData: result.data.data });
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr, { id: createPagesToast });
        setState({ ...state, error: errorStr });
        return false;
      }
    } catch (error: any) {
      toast.error(error.message, { id: createPagesToast });
      setState({ ...state, error: error.message });
      return false;
    }
  };

  //Edit
  const editPages = async (gameId: string) => {
    try {
      const result: any = await api.get(`/api/v1/pages/${gameId}`);

      if (result.data.status) {
        setState({
          ...state,
          curPagesEditData: result.data.data,
        });

        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr);
        setState({ ...state, error: errorStr });
        return false;
      }
    } catch (error: any) {
      toast.error(error.message);
      setState({ ...state, error: error.message });
      return false;
    }
  };

  const updatePages = async (gameId: string, param: any) => {
    const paramObj = {
      ...param,
    };
    try {
      const result: any = await api.patch(`/api/v1/pages/${gameId}`, paramObj, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (result.data.status) {
        toast.success(result.data.message, {
          id: "updatePagesToast",
        });
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr, { id: "updatePagesToastError" });
        setState({ ...state, error: errorStr });
        return false;
      }
    } catch (error: any) {
      toast.error(error.message, { id: "updatePagesToastError" });
      setState({ ...state, error: error.message });
      return false;
    }
  };

  const removePages = async (id: string) => {
    try {
      const result: any = await api.delete(
        `/api/v1/pages/removePages?Id=${id}`,
      );

      if (result.data.status) {
        toast.success(result.data.message);
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr);
        setState({ ...state, error: errorStr });

        return false;
      }
    } catch (error: any) {
      toast.error(error.message);
      setState({ ...state, error: error.message });

      return false;
    }
  };

  return {
    updatePages,
    getPages,
    createPages,
    editPages,
    removePages,
    ...state,
  };
};

export default usePages;
