import { flatMap, get, has } from "lodash";
import { useReducer } from "react";
import toast from "react-hot-toast";
import api from "../../apis/axiosInterceptor";

interface Matches {
  matchesData: object;
  matchesAllData: object;
  totalPageCount: number;
  totalData: number;
  error: string;
}

const initialArgs: Matches = {
  matchesData: {},
  matchesAllData: {},
  totalPageCount: 0,
  totalData: 17,
  error: "",
};

const reducer = (state: Matches, action: Matches) => {
  return { ...state, ...action };
};

const useMatches = () => {
  const [state, setState] = useReducer(reducer, initialArgs);

  const getMatches = async (
    page: string,
    listPerPage: string,
    searchText: string,
  ) => {
    try {
      const result: any = await api.get(
        `/api/v1/events/matches?page=${page}&listPerPage=${listPerPage}&search=${searchText}`,
      );

      if (result.data.status) {
        setState({
          ...state,
          matchesData: result.data.data.data,
          totalPageCount: get(result, "data.data.totalPageCount", 0),
          totalData: get(result, "data.data.totalData", 0),
        });

        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr);
        setState({ ...state, error: errorStr });

        return false;
      }
    } catch (error: any) {
      toast.error(error.message);
      setState({ ...state, error: error.message });

      return false;
    }
  };

  const createMatches = async (param: any) => {
    console.info("param => ", param);

    const paramObj = {
      ...param,
    };
    paramObj.winner_type == 1 ? "Team" : "Team";
    paramObj.status =
      paramObj.status == 1
        ? "not_started"
        : paramObj.status == 2
          ? "finished"
          : paramObj.status == 3
            ? "postponed"
            : "canceled";
    let createMatchesToast;
    try {
      const result: any = await api.post("/api/v1/events/matches", paramObj, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (result.data.status) {
        createMatchesToast = toast.success(result.data.message, {
          id: createMatchesToast,
        });
        //toast.dismiss(createMatchesToast);
        setState({ ...state, matchesData: result.data.data });
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr, { id: createMatchesToast });
        setState({ ...state, error: errorStr });
        return false;
      }
    } catch (error: any) {
      toast.error(error.message, { id: createMatchesToast });
      setState({ ...state, error: error.message });
      return false;
    }
  };

  //Edit
  const editMatches = async (teamsId: string) => {
    try {
      const result: any = await api.get(`/api/v1/events/matches/${teamsId}`);

      if (result.data.status) {
        return result.data.data;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr);
        setState({ ...state, error: errorStr });
        throw errorStr;
      }
    } catch (error: any) {
      toast.error(error.message);
      setState({ ...state, error: error.message });
      throw error;
    }
  };

  const updateMatches = async (teamsId: string, param: any) => {
    const paramObj = {
      ...param,
      id: teamsId,
    };
    try {
      paramObj.winner_type == 1 ? "Team" : "Team";
      paramObj.status =
        paramObj.status == 1
          ? "not_started"
          : paramObj.status == 2
            ? "finished"
            : paramObj.status == 3
              ? "postponed"
              : "canceled";
      const result: any = await api.patch(`/api/v1/events/matches`, paramObj, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (result.data.status) {
        toast.success(result.data.message, {
          id: "updateMatchesToast",
        });
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr, { id: "updateMatchesToastError" });
        setState({ ...state, error: errorStr });
        return false;
      }
    } catch (error: any) {
      toast.error(error.message, { id: "updateMatchesToastError" });
      setState({ ...state, error: error.message });
      return false;
    }
  };

  const removeMatches = async (id: string) => {
    try {
      const result: any = await api.delete(
        `/api/v1/events/matches/removeMatches?Id=${id}`,
      );

      if (result.data.status) {
        toast.success(result.data.message);
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr);
        setState({ ...state, error: errorStr });

        return false;
      }
    } catch (error: any) {
      toast.error(error.message);
      setState({ ...state, error: error.message });

      return false;
    }
  };

  const getAllMatches = async () => {
    try {
      const result: any = await api.get(`/api/v1/events/matches/all`);

      if (result.data.status) {
        setState({ ...state, matchesAllData: result.data.data });

        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr);
        setState({ ...state, error: errorStr });

        return false;
      }
    } catch (error: any) {
      toast.error(error.message);
      setState({ ...state, error: error.message });

      return false;
    }
  };
  return {
    updateMatches,
    getMatches,
    createMatches,
    editMatches,
    removeMatches,
    getAllMatches,
    ...state,
  };
};

export default useMatches;
