import { flatMap, has } from "lodash";
import { useReducer } from "react";
import toast from "react-hot-toast";
import api from "../../apis/axiosInterceptor";
import { setGetLsEncryptDecryptData } from "../../shared/functions";
interface Profile {
  profileData: object;
  isLoading: boolean;
  isProfileDataLoad: boolean;
  isFetchedStateList: boolean;
  isProfileUpdated: boolean;
  error: string;
}

const initialArgs: Profile = {
  profileData: {},
  isFetchedStateList: false,
  isProfileDataLoad: false,
  isProfileUpdated: false,
  isLoading: false,
  error: "",
};

const reducer = (state: Profile, action: Profile) => {
  return { ...state, ...action };
};

const useProfile = () => {
  const [state, setState] = useReducer(reducer, initialArgs);

  const getUserProfileData = async (id: string) => {
    const getProfileToast = toast.loading("Profile fetching...");
    setState({ ...state, isLoading: true });
    try {
      const result: any = await api.get(`/api/v1/users/profile/${id}`);

      if (result.data.status) {
        toast.success("Profile fetched", { id: getProfileToast });
        setState({
          ...state,
          isLoading: false,
          isProfileDataLoad: true,
          profileData: result.data.data,
        });
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr, { id: getProfileToast });
        setState({
          ...state,
          isLoading: false,
          isProfileDataLoad: false,
          error: errorStr,
        });
        return false;
      }
    } catch (error: any) {
      toast.error(error.message, { id: getProfileToast });
      setState({
        ...state,
        isLoading: false,
        isProfileDataLoad: false,
        error: error.message,
      });
      return false;
    }
  };

  //Update profile
  const updateUserProfileData = async (id: string, param: any) => {
    const paramObj = {
      first_name: param.firstName,
      last_name: param.lastName,
      email: param.email,
      profile_pic: param.profile_pic,
      mobile_no: param.mobile_no,
      id: id,
    };

    let updateProfileToast;
    try {
      const result: any = await api.patch(
        `/api/v1/users/updateprofile`,
        paramObj,
        {
          headers: { "Content-Type": "multipart/form-data" },
        },
      );

      if (result.data.status || result.data.staus) {
        toast.success(result.data.message, { id: updateProfileToast });
        setState({ ...state, isProfileUpdated: true });

        const userData: any =
          localStorage.getItem("userData") !== "undefined"
            ? setGetLsEncryptDecryptData("get", "userData") === "false"
              ? {}
              : setGetLsEncryptDecryptData("get", "userData")
            : {};

        userData.first_name = paramObj.first_name;
        userData.last_name = paramObj.last_name;

        if (result.data.profileImage) {
          userData.profile_pic = result.data.profileImage;
        }

        // localStorage.setItem("userData", JSON.stringify(userData));
        setGetLsEncryptDecryptData("set", "userData", JSON.stringify(userData));
        return result.data;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr, { id: updateProfileToast });
        setState({ ...state, isProfileUpdated: false, error: errorStr });
        return false;
      }
    } catch (error: any) {
      toast.error(error.message, { id: updateProfileToast });
      setState({ ...state, isProfileUpdated: false, error: error.message });
      return false;
    }
  };

  const changePasswordApi = async (
    curPassword: string,
    newPassword: string,
    confPassword: string,
  ) => {
    let changePasswordToast;
    try {
      const result: any = await api.post("/api/v1/users/changePassword", {
        currentpassword: curPassword,
        newpassword: newPassword,
        confirmpassword: confPassword,
      });

      if (result.data.status) {
        changePasswordToast = toast.success(result.data.message, {
          id: changePasswordToast,
        });
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr, { id: changePasswordToast });
        setState({ ...state, error: errorStr });
        return false;
      }
    } catch (error: any) {
      toast.error(error.message, { id: changePasswordToast });
      setState({ ...state, error: error.message });
      return false;
    }
  };

  return {
    updateUserProfileData,
    changePasswordApi,
    getUserProfileData,
    ...state,
  };
};

export default useProfile;
