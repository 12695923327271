import { has } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import allImgPaths from "../../assets";
import { useAppState } from "../../context";
import Login from "./Login";
import NewPassword from "./NewPassword";
import OtpForm from "./OtpForm";

const Auth = ({ type }: { type: string }) => {
  const navigate = useNavigate();
  const { isLoggedIn, userData, logout } = useAppState("auth");

  const [, setStep] = useState(type);

  useEffect(() => {
    setStep(type);
  }, [type]);

  useEffect(() => {
    logout();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      if (has(userData, "role_name")) {
        navigate("/dashboard");
      }
    }
  }, [isLoggedIn]);

  const RenderComponent = ({ screenId }: { screenId: string }) => {
    let result = <></>;
    switch (screenId) {
      case "1":
        result = <Login />;
        break;

      case "2":
        result = <OtpForm />;
        break;

      case "3":
        result = <NewPassword />;
        break;

      default:
        break;
    }

    return result;
  };

  return (
    <div>
      <div className="flex h-lvh">
        <div className="relative container mx-auto max-w-sm flex flex-col justify-center">
          <div className="flex justify-center">
            <div className="top-10 relative">
              <img src={allImgPaths.talesportLogo2} alt="" />
            </div>
          </div>
          <div className="flex justify-center">
            <div className="top-10 relative">
              <img
                src={allImgPaths.talesportLogo1}
                className="rounded-full w-24 h-20"
                alt=""
              />
            </div>
          </div>
          <div>
            <RenderComponent screenId={type} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
