export const SCREEN_SIZE = {
  xs: "0px",
  sm: "600px",
  md: "900px",
  lg: "1200px",
  xl: "1536px",
};

export const HTTPS = {
  OK: 200,
  INTERNAL_SERVER_ERROR: 500,
  PAGE_NOT_FOUND: 404,
};

export const SECTION = {
  type: "section",
  id: "",
  list_style: "none",
  title: "Section",
  align: "center",
  sectionMark: 0,
  lableCount: 0,
  hasOr: false,
  showMark: true,
  children: [],
};

export const LABEL = {
  type: "label",
  id: "",
  value: 0,
  list_style: "none",
  marks: 0,
  title: "Label",
  align: "left",
  qtype: 0,
  questionCount: 0,
  labelMark: 0,
  mandatoryQuestion: 0,
  hasOr: false,
  showMark: true,
  children: [],
  continueSequence: false,
  boldNumbers: false,
  sameNumberForOR: false,
};

export const QUESTION = {
  type: "question",
  id: "",
  labelId: "",
  list_style: "decimal",
  marks: 0,
  title: "Question",
  align: "left",
  qtype: null,
  showMark: true,
  chapter_list: [
    {
      check: [],
      list: [],
    },
  ],
  que_types: [
    {
      check: [],
      list: [],
    },
  ],
  difficulty_list: [
    {
      check: [],
      list: [],
    },
  ],
  importance_list: [
    {
      check: [],
      list: [],
    },
  ],
};

export const OR = {
  type: "or",
  id: "",
  list_style: "none",
  marks: 0,
  title: "<b>OR</b>",
  align: "justify",
  qtype: null,
};

export const scoreList = [
  { id: 0, name: "0" },
  { id: 1, name: "1" },
  { id: 2, name: "2" },
  { id: 3, name: "3" },
  { id: 4, name: "4" },
  { id: 5, name: "5" },
  { id: 6, name: "6" },
  { id: 7, name: "7" },
  { id: 8, name: "8" },
  { id: 9, name: "9" },
  { id: 10, name: "10" },
  { id: 11, name: "11" },
  { id: 12, name: "12" },
  { id: 13, name: "13" },
  { id: 14, name: "14" },
  { id: 15, name: "15" },
  { id: 16, name: "16" },
  { id: 17, name: "17" },
  { id: 18, name: "18" },
  { id: 19, name: "19" },
  { id: 20, name: "20" },
  { id: 21, name: "21" },
  { id: 22, name: "22" },
];

export const MARKS = [
  {
    id: 0,
    name: "All marks",
  },
  {
    id: 1,
    name: "1",
  },
  {
    id: 2,
    name: "2",
  },
  {
    id: 3,
    name: "3",
  },
  {
    id: 4,
    name: "4",
  },
  {
    id: 5,
    name: "5",
  },
  {
    id: 6,
    name: "6",
  },
  {
    id: 7,
    name: "7",
  },
  {
    id: 8,
    name: "8",
  },
  {
    id: 9,
    name: "9",
  },
  {
    id: 10,
    name: "10",
  },
];

export const STEPS = [
  { name: "Blueprint", id: 1 },
  { name: "Question Selection", id: 2 },
  { name: "Header Design", id: 3 },
  { name: "Preview", id: 4 },
];

export const PAGE_LIMIT = 10;
export const PAGE_LIMIT_OPTIONS = [10, 25, 50, 100];

export const CONSTANTS = {
  SECURE_TEXT_ROUNDS: 6,
  SECURE_ENC_DEC_KEY:
    "WFGCbvIqWaWTmTUKRos4MZK3McH9AB4tgr1YtSf8WpKXxDixu9lzBvbPBiY2rVOH",
};
