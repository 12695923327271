import { flatMap, get, has } from "lodash";
import { useReducer } from "react";
import toast from "react-hot-toast";
import api from "../../apis/axiosInterceptor";

interface Series {
  seriesData: object;
  seriesAllData: object;
  totalPageCount: number;
  totalData: number;
  error: string;
}

const initialArgs: Series = {
  seriesData: {},
  seriesAllData: {},
  totalPageCount: 0,
  totalData: 17,
  error: "",
};

const reducer = (state: Series, action: Series) => {
  return { ...state, ...action };
};

const useSeries = () => {
  const [state, setState] = useReducer(reducer, initialArgs);

  const getSeries = async (
    page: string,
    listPerPage: string,
    searchText: string,
  ) => {
    try {
      const result: any = await api.get(
        `/api/v1/events/series?page=${page}&listPerPage=${listPerPage}&search=${searchText}`,
      );

      if (result.data.status) {
        setState({
          ...state,
          seriesData: result.data.data.data,
          totalPageCount: get(result, "data.data.totalPageCount", 0),
          totalData: get(result, "data.data.totalData", 0),
        });

        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr);
        setState({ ...state, error: errorStr });

        return false;
      }
    } catch (error: any) {
      toast.error(error.message);
      setState({ ...state, error: error.message });

      return false;
    }
  };

  const createSeries = async (param: any) => {
    console.info("param => ", param);

    const paramObj = {
      ...param,
    };

    let createSeriesToast;
    try {
      const result: any = await api.post("/api/v1/events/series", paramObj, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (result.data.status) {
        createSeriesToast = toast.success(result.data.message, {
          id: createSeriesToast,
        });
        //toast.dismiss(createSeriesToast);
        setState({ ...state, seriesData: result.data.data });
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr, { id: createSeriesToast });
        setState({ ...state, error: errorStr });
        return false;
      }
    } catch (error: any) {
      toast.error(error.message, { id: createSeriesToast });
      setState({ ...state, error: error.message });
      return false;
    }
  };

  //Edit
  const editSeries = async (teamsId: string) => {
    try {
      const result: any = await api.get(`/api/v1/events/series/${teamsId}`);

      if (result.data.status) {
        return result.data.data;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr);
        setState({ ...state, error: errorStr });
        throw errorStr;
      }
    } catch (error: any) {
      toast.error(error.message);
      setState({ ...state, error: error.message });
      throw error;
    }
  };

  const updateSeries = async (teamsId: string, param: any) => {
    const paramObj = {
      ...param,
      id: teamsId,
    };
    try {
      const result: any = await api.patch(`/api/v1/events/series`, paramObj, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (result.data.status) {
        toast.success(result.data.message, {
          id: "updateSeriesToast",
        });
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr, { id: "updateSeriesToastError" });
        setState({ ...state, error: errorStr });
        return false;
      }
    } catch (error: any) {
      toast.error(error.message, { id: "updateSeriesToastError" });
      setState({ ...state, error: error.message });
      return false;
    }
  };

  //   try {
  //     const result: any = await api.delete(`/api/v1/teacher?userId=${userId}`);

  //     if (result.data.status) {
  //       toast.success("Teacher Deleted has been successfully", {
  //         id: "deleteTeacherToast",
  //       });
  //       return true;
  //     } else {
  //       const errorStr = has(result.data, "errors")
  //         ? Object(flatMap(result.data))[1].msg
  //         : result.data.message;
  //       toast.error(errorStr);
  //       setState({ ...state, error: errorStr });

  //       return false;
  //     }
  //   } catch (error: any) {
  //     console.info("error => ", error);

  //     toast.error(error.message);
  //     setState({ ...state, error: error.message });

  //     return false;
  //   }
  // };

  // const getCountryList = async () => {
  //   try {
  //     const result: any = await api.get(`/api/v1/users/country`);

  //     if (result.data.status) {
  //       setState({ ...state, countryList: result.data.data });
  //       return true;
  //     } else {
  //       const errorStr = has(result.data, "errors")
  //         ? Object(flatMap(result.data))[1].msg
  //         : result.data.message;
  //       setState({ ...state, error: errorStr });
  //       return false;
  //     }
  //   } catch (error: any) {
  //     setState({ ...state, error: error.message });
  //     return false;
  //   }
  // };

  const removeSeries = async (id: string) => {
    try {
      const result: any = await api.delete(
        `/api/v1/events/series/removeSeries?Id=${id}`,
      );

      if (result.data.status) {
        toast.success(result.data.message);
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr);
        setState({ ...state, error: errorStr });

        return false;
      }
    } catch (error: any) {
      toast.error(error.message);
      setState({ ...state, error: error.message });

      return false;
    }
  };

  const getAllSeries = async () => {
    try {
      const result: any = await api.get(`/api/v1/events/series/all`);

      if (result.data.status) {
        setState({ ...state, seriesAllData: result.data.data });

        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr);
        setState({ ...state, error: errorStr });

        return false;
      }
    } catch (error: any) {
      toast.error(error.message);
      setState({ ...state, error: error.message });

      return false;
    }
  };
  return {
    updateSeries,
    getSeries,
    createSeries,
    editSeries,
    // deletePlayer,
    removeSeries,
    // getCountryList,
    getAllSeries,
    ...state,
  };
};

export default useSeries;
