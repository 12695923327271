import * as Yup from "yup";

export const profileValidation = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[A-Za-z0-9\s].*$/, "First Name must not contain spaces")
    .required("First Name is required"),
  lastName: Yup.string()
    .matches(/^[A-Za-z0-9\s].*$/, "Last Name must not contain spaces")
    .required("Last Name is required"),
  phone: Yup.string()
    .min(10, "Please enter a valid 10 digits Mobile Number")
    .max(10, "Please enter a valid 10 digits Mobile Number")
    .matches(/^\d{10}$/, "Please enter a valid 10 digits Mobile Number")
    .notRequired(),
  //phone: Yup.string().notRequired(),
  //password: Yup.string().notRequired(),
  // password: Yup.string()
  //   .min(8, "Password is too short - should be 8 chars minimum.")
  //   .max(16, "must be less then 16 char")
  //   .matches(
  //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!+@#\\$%\\^&\\*])(?=.{8,})/,
  //     "Password field must contain at least 1 Uppercase, 1 Lowercase, 1 Special Character, and 1 Number.",
  //   )
  //   .notRequired(),
  //.required('Password is required'),
  // country: Yup.string().required("Country is required"),
  // state: Yup.string().required("State is required"),
  // city: Yup.string().required("City is required"),
  // pincode: Yup.string()
  //   .matches(/^\d{6}$/, "Pincode must be 6 digits")
  //   .notRequired(),
});

//const regExp = ("/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!+@#\\$%\\^&\\*])(?=.{8,})/");

export const LoginFormSchema = Yup.object().shape({
  username: Yup.string()
    .matches(/^\S*$/, "Username must not contain spaces")
    .required("Username is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password field contain minimum 8 characters")
    .max(16, "Please enter maximum 16 characters"), //Password field must be less then 16 characters.
  // .matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!+@#\\$%\\^&\\*])(?=.{8,})/,
  //   "Password field must contain at least 1 Uppercase, 1 Lowercase, 1 Special Character, and 1 Number.",
  // ),
});

export const otpFormSchema = Yup.object().shape({
  username: Yup.string()
    .matches(/^\S*$/, "Username must not contain spaces")
    .required("Username is required"),
  otp: Yup.string()
    .matches(/^\d{6}$/, "OTP must be exactly 6 digits")
    .required("OTP is required"),
  //mobile: Yup.string()
  //  .required('Mobile number is required')
});

export const sendOtpFormSchema = (isUsername: boolean) => {
  if (isUsername) {
    return Yup.object({
      textField: Yup.string()
        .matches(/^\S*$/, "Username must not contain spaces")
        .required("Username is required"),
      otp: Yup.string()
        .matches(/^\d{6}$/, "OTP must be exactly 6 digits")
        .required("OTP is required"),
    });
  } else {
    return Yup.object({
      textField: Yup.string()
        .required("Mobile Number is required")
        .min(10, "Please enter a valid 10 digits Mobile Number")
        .max(10, "Please enter a valid 10 digits Mobile Number")
        //.matches(/^\d{10}$/, 'Mobile Number must be 10 digits')
        .matches(new RegExp("[0-9]{7}")),
      otp: Yup.string()
        .matches(/^\d{6}$/, "OTP must be exactly 6 digits")
        .required("OTP is required"),
    });
  }
};

export const NewPasswordFormSchema = Yup.object().shape({
  password: Yup.string()
    .required("Please enter New Password")
    .min(8, "New Password field contain minimum 8 characters")
    .max(16, "Please enter maximum 16 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!+@#\\$%\\^&\\*])(?=.{8,})/,
      "Password field must contain at least 1 Uppercase, 1 Lowercase, 1 Special Character and 1 Number",
    ),
  passwordConfirmation: Yup.string()
    .oneOf(
      [Yup.ref("password"), undefined],
      "New Password and Confirm Password does not match",
    )
    .required("Please enter Confirm Password"),
});

export const ChangePasswordFormSchema = Yup.object().shape({
  oldpassword: Yup.string()
    .required("Please enter Current Password")
    .min(8, "Password field contain minimum 8 characters")
    .max(16, "Please enter maximum 16 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!+@#\\$%\\^&\\*])(?=.{8,})/,
      "Current Password field must contain at least 1 Uppercase, 1 Lowercase, 1 Special Character and 1 Number",
    ),
  newpassword: Yup.string()
    .required("Please enter New Password")
    .min(8, "New Password field contain minimum 8 characters")
    .max(16, "Please enter maximum 16 characters") //New Password field must be less then 16 characters.
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!+@#\\$%\\^&\\*])(?=.{8,})/,
      "New Password field must contain at least 1 Uppercase, 1 Lowercase, 1 Special Character and 1 Number",
    ),
  confirmPassword: Yup.string()
    .required("Please enter your Confirm Password.")
    .oneOf(
      [Yup.ref("newpassword")],
      "New Password and Confirm Password does not match",
    ),
});

export const ResetPasswordFormSchema = Yup.object().shape({
  newpassword: Yup.string()
    .required("Please enter New Password")
    .min(8, "New Password field contain minimum 8 characters")
    .max(16, "Please enter maximum 16 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!+@#\\$%\\^&\\*])(?=.{8,})/,
      "New Password field must contain at least 1 Uppercase, 1 Lowercase, 1 Special Character and 1 Number",
    ),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref("newpassword"), undefined],
      "New Password and Confirm Password does not match",
    )
    .required("Please enter Confirm Password"),
});

//export const otpSchema = Yup.string().length(1, 'OTP must be exactly 1 character').matches(/^[0-9]+$/, 'OTP must only contain numbers');
export const otpSchema = Yup.string()
  .matches(/^\d{6}$/, "OTP must be exactly 6 digits")
  .required("OTP is required");

export const createGamesFormSchema = (forEdit: boolean) => {
  if (forEdit) {
    return Yup.object().shape({
      name: Yup.string()
        .required("Name is required")
        .max(30, "Please enter your Name with a maximum of 30 characters")
        .trim("The Name cannot include leading and trailing spaces")
        .strict(true),
      full_name: Yup.string()
        .required("Full name is required")
        .max(30, "Please enter your Full name with a maximum of 30 characters")
        .trim("The Full name cannot include leading and trailing spaces")
        .strict(true),
      twitch_id: Yup.string().required("Twitch id is required"),
      pandascore_id: Yup.string().required("Pandascore id is required"),
    });
  } else {
    return Yup.object().shape({
      name: Yup.string()
        .required("Name is required")
        .max(30, "Please enter your Name with a maximum of 30 characters")
        .trim("The Name cannot include leading and trailing spaces")
        .strict(true),
      full_name: Yup.string()
        .required("Full name is required")
        .max(30, "Please enter your Full name with a maximum of 30 characters")
        .trim("The Full name cannot include leading and trailing spaces")
        .strict(true),
      twitch_id: Yup.string().required("Twitch id is required"),
      pandascore_id: Yup.string().required("Pandascore id is required"),
    });
  }
};

export const createYoutubeFormSchema = (forEdit: boolean) => {
  if (forEdit) {
    return Yup.object().shape({
      name: Yup.string().required("Name is required"),
    });
  } else {
    return Yup.object().shape({
      name: Yup.string().required("Name is required"),
    });
  }
};

/* export const createTeachersFormSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  phone: Yup.string()
    .matches(/^\d+$/, "Phone number must contain only digits")
    .required("Phone is required"),
  username: Yup.string().required("Username is required"),
  password: Yup.string()
    .min(8, "Password field contain minimum 8 characters.")
    .max(16, "Password field must be less then 16 characters.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!+@#\\$%\\^&\\*])(?=.{8,})/,
      "Password field must contain at least 1 Uppercase, 1 Lowercase, 1 Special Character, and 1 Number.",
    )
    .notRequired(),
  gender: Yup.string()
    .oneOf(["Male", "Female", "Other"], "Invalid gender")
    .required("Gender is required"),
  category: Yup.array()
    .of(
      Yup.object().shape({
        category_id: Yup.number()
          .required("Category ID is required")
          .nullable(),
        course_id: Yup.number().required("Course ID is required").nullable(),
      }),
    )
    .min(1, "Please select atleast one category before proceeding."),
  status: Yup.string().required("Status is required"),
}); */

export const importCsvFormSchema = Yup.object().shape({
  file: Yup.string().notRequired(),
});

export const createAdvertiseFormSchema = (forEdit: boolean) => {
  if (forEdit) {
    return Yup.object().shape({
      name: Yup.string()
        .required("Name is required")
        .max(30, "Please enter your Name with a maximum of 30 characters")
        .trim("The Name cannot include leading and trailing spaces")
        .strict(true),
      // full_name: Yup.string()
      //   .required("Full name is required")
      //   .max(30, "Please enter your Full name with a maximum of 30 characters")
      //   .trim("The Full name cannot include leading and trailing spaces")
      //   .strict(true),
    });
  } else {
    return Yup.object().shape({
      name: Yup.string()
        .required("Name is required")
        .max(30, "Please enter your Name with a maximum of 30 characters")
        .trim("The Name cannot include leading and trailing spaces")
        .strict(true),
      // full_name: Yup.string()
      //   .required("Full name is required")
      //   .max(30, "Please enter your Full name with a maximum of 30 characters")
      //   .trim("The Full name cannot include leading and trailing spaces")
      //   .strict(true),
    });
  }
};

export const createPagesFormSchema = (forEdit: boolean) => {
  if (forEdit) {
    return Yup.object().shape({
      name: Yup.string()
        .required("Name is required")
        .max(30, "Please enter your Name with a maximum of 30 characters")
        .trim("The Name cannot include leading and trailing spaces")
        .strict(true),
      // description: Yup.string().required("Full name is required").strict(true),
    });
  } else {
    return Yup.object().shape({
      name: Yup.string()
        .required("Name is required")
        .max(30, "Please enter your Name with a maximum of 30 characters")
        .trim("The Name cannot include leading and trailing spaces")
        .strict(true),
      // description: Yup.string().required("Full name is required").strict(true),
    });
  }
};

export const createPlyerFormSchema = (forEdit: boolean) => {
  if (forEdit) {
    return Yup.object().shape({
      nickname: Yup.string()
        .required("Nickname is required")
        .max(30, "Please enter your Nickname with a maximum of 30 characters")
        .trim("The Nickname cannot include leading and trailing spaces")
        .strict(true),
      first_name: Yup.string()
        .required("First Name is required")
        .max(30, "Please enter your First Name with a maximum of 30 characters")
        .trim("The First Name cannot include leading and trailing spaces")
        .strict(true),
      last_name: Yup.string()
        .required("First Name is required")
        .max(30, "Please enter your First Name with a maximum of 30 characters")
        .trim("The First Name cannot include leading and trailing spaces")
        .strict(true),
      // description: Yup.string().required("Full name is required").strict(true),
    });
  } else {
    return Yup.object().shape({
      nickname: Yup.string()
        .required("Nickname is required")
        .max(30, "Please enter your Nickname with a maximum of 30 characters")
        .trim("The Nickname cannot include leading and trailing spaces")
        .strict(true),
      first_name: Yup.string()
        .required("First Name is required")
        .max(30, "Please enter your First Name with a maximum of 30 characters")
        .trim("The First Name cannot include leading and trailing spaces")
        .strict(true),
      last_name: Yup.string()
        .required("First Name is required")
        .max(30, "Please enter your First Name with a maximum of 30 characters")
        .trim("The First Name cannot include leading and trailing spaces")
        .strict(true),
      current_game_id: Yup.string().required("Game is required"),
      current_team_id: Yup.string().required("Team is required"),
    });
  }
};

export const createTeamsFormSchema = (forEdit: boolean) => {
  if (forEdit) {
    return Yup.object().shape({
      name: Yup.string()
        .required("Name is required")
        .max(30, "Please enter your Name with a maximum of 30 characters")
        .trim("The Name cannot include leading and trailing spaces")
        .strict(true),
      // description: Yup.string().required("Full name is required").strict(true),
    });
  } else {
    return Yup.object().shape({
      name: Yup.string()
        .required("Name is required")
        .max(30, "Please enter your Name with a maximum of 30 characters")
        .trim("The Name cannot include leading and trailing spaces")
        .strict(true),
      game_id: Yup.string().required("Game is required"),
      // description: Yup.string().required("Full name is required").strict(true),
    });
  }
};

export const createLeagueFormSchema = (forEdit: boolean) => {
  if (forEdit) {
    return Yup.object().shape({
      name: Yup.string()
        .required("Name is required")
        .max(30, "Please enter your Name with a maximum of 30 characters")
        .trim("The Name cannot include leading and trailing spaces")
        .strict(true),
      // description: Yup.string().required("Full name is required").strict(true),
    });
  } else {
    return Yup.object().shape({
      name: Yup.string()
        .required("Name is required")
        .max(30, "Please enter your Name with a maximum of 30 characters")
        .trim("The Name cannot include leading and trailing spaces")
        .strict(true),
      game_id: Yup.string().required("Game is required"),
      // description: Yup.string().required("Full name is required").strict(true),
    });
  }
};

export const createSeriesFormSchema = (forEdit: boolean) => {
  if (forEdit) {
    return Yup.object().shape({
      name: Yup.string()
        .required("Name is required")
        .max(30, "Please enter your Name with a maximum of 30 characters")
        .trim("The Name cannot include leading and trailing spaces")
        .strict(true),
      full_name: Yup.string()
        .required("Full Name is required")
        .max(50, "Please enter your Full Name with a maximum of 50 characters")
        .trim("The Full Name cannot include leading and trailing spaces")
        .strict(true),
      // description: Yup.string().required("Full name is required").strict(true),
    });
  } else {
    return Yup.object().shape({
      name: Yup.string()
        .required("Name is required")
        .max(30, "Please enter your Name with a maximum of 30 characters")
        .trim("The Name cannot include leading and trailing spaces")
        .strict(true),
      full_name: Yup.string()
        .required("Full Name is required")
        .max(50, "Please enter your Full Name with a maximum of 50 characters")
        .trim("The Full Name cannot include leading and trailing spaces")
        .strict(true),
      game_id: Yup.string().required("Game is required"),
      league_id: Yup.string().required("League is required"),
      winner_type: Yup.string().required("Winner Type is required"),
      winner_id: Yup.string().required("Winner Team is required"),
      begin_at: Yup.string().required("Start Date is required"),
      end_at: Yup.string().required("End Date is required"),

      // description: Yup.string().required("Full name is required").strict(true),
    });
  }
};

export const createUserFormSchema = (forEdit: boolean) => {
  if (forEdit) {
    return Yup.object().shape({
      name: Yup.string()
        .required("Name is required")
        .max(30, "Please enter your Name with a maximum of 30 characters")
        .trim("The Name cannot include leading and trailing spaces")
        .strict(true),
      first_name: Yup.string()
        .required("Full Name is required")
        .max(50, "Please enter your Full Name with a maximum of 50 characters")
        .trim("The Full Name cannot include leading and trailing spaces")
        .strict(true),
      last_name: Yup.string().required("Last Name is required"),
    });
  } else {
    return Yup.object().shape({
      name: Yup.string()
        .required("Name is required")
        .max(30, "Please enter your Name with a maximum of 30 characters")
        .trim("The Name cannot include leading and trailing spaces")
        .strict(true),
      first_name: Yup.string()
        .required("Full Name is required")
        .max(50, "Please enter your Full Name with a maximum of 50 characters")
        .trim("The Full Name cannot include leading and trailing spaces")
        .strict(true),
      last_name: Yup.string().required("Last Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      // password: Yup.string().required("Password is required"),
      password: Yup.string()
        .required("Password is required")
        .min(8, "Password field contain minimum 8 characters")
        .max(16, "Please enter maximum 16 characters")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!+@#\\$%\\^&\\*])(?=.{8,})/,
          "Password field must contain at least 1 Uppercase, 1 Lowercase, 1 Special Character, and 1 Number.",
        ),
      mobile_no: Yup.string().required("Mobile No is required"),
      // description: Yup.string().required("Full name is required").strict(true),
    });
  }
};

// const formSchema = {
//   fieldId: string().required("Name is requied"),
// };

export const createTournamentFormSchema = (forEdit: boolean) => {
  if (forEdit) {
    return Yup.object().shape({
      name: Yup.string()
        .required("Name is required")
        .max(30, "Please enter your Name with a maximum of 30 characters")
        .trim("The Name cannot include leading and trailing spaces")
        .strict(true),
      // description: Yup.string().required("Full name is required").strict(true),
      teams: Yup.array().of(
        Yup.object().shape({
          fieldId: Yup.string().required("Team is required").nullable(),
        }),
      ),
    });
  } else {
    return Yup.object().shape({
      name: Yup.string()
        .required("Name is required")
        .max(30, "Please enter your Name with a maximum of 30 characters")
        .trim("The Name cannot include leading and trailing spaces")
        .strict(true),
      game_id: Yup.string().required("Game is required"),
      // serie_id: Yup.string().required("Series is required"),
      // league_id: Yup.string().required("League is required"),
      // winner_type: Yup.string().required("Winner Type is required"),
      // winner_id: Yup.string().required("Winner Team is required"),
      begin_at: Yup.string().required("Start Date is required"),
      // end_at: Yup.string().required("End Date is required"),
      teams: Yup.array().of(
        Yup.object().shape({
          fieldId: Yup.string().required("Team is required").nullable(),
        }),
      ),
      // description: Yup.string().required("Full name is required").strict(true),
    });
  }
};

export const createMatchesFormSchema = (forEdit: boolean) => {
  if (forEdit) {
    return Yup.object().shape({
      name: Yup.string()
        .required("Name is required")
        .max(30, "Please enter your Name with a maximum of 30 characters")
        .trim("The Name cannot include leading and trailing spaces")
        .strict(true),
      // description: Yup.string().required("Full name is required").strict(true),
    });
  } else {
    return Yup.object().shape({
      name: Yup.string()
        .required("Name is required")
        .max(30, "Please enter your Name with a maximum of 30 characters")
        .trim("The Name cannot include leading and trailing spaces")
        .strict(true),
      game_id: Yup.string().required("Game is required"),
      // series_id: Yup.string().required("Series is required"),
      tournament_id: Yup.string().required("Tournament is required"),
      // winner_type: Yup.string().required("Winner Type is required"),
      // winner_id: Yup.string().required("Winner Team is required"),
      // begin_at: Yup.string().required("Start Date is required"),
      // end_at: Yup.string().required("End Date is required"),
      status: Yup.string().required("Status is required"),
      original_scheduled_at: Yup.string().required(
        "Original Scheduled Date is required",
      ),
      // opponents1_id: Yup.string().required("Opponents Team 1 is required"),
      // opponents2_id: Yup.string().required("Opponents Team 2 is required"),
      // description: Yup.string().required("Full name is required").strict(true),
    });
  }
};

export const createCategoryFormSchema = (forEdit: boolean) => {
  if (forEdit) {
    return Yup.object().shape({
      category: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().required("Category Id is required"),
          name: Yup.string().required("Category Name is required"),
        }),
      ),
    });
  } else {
    return Yup.object().shape({
      category: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().required("Category Id is required"),
          name: Yup.string().required("Category Name is required"),
        }),
      ),
      // description: Yup.string().required("Full name is required").strict(true),
    });
  }
};

/* .min(8, "Password is too short - should be 8 chars minimum.")
    .max(16, "must be less then 16 char")
    .required('Passwords must match') */
