import { Tooltip } from "flowbite-react";
import { cloneDeep, findIndex, get, size } from "lodash";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import allImgPaths from "../../assets";
import useOutsideClick from "../../hooks/useOutsideClick";
import Checkbox from "./Checkbox";

interface Props {
  name: string;
  loading?: boolean;
  value: any;
  className?: string;
  error?: string;
  trigger: any;
  options: any[];
  isShowSelectedCount?: boolean;
  onChange: (opt: any) => void;
}
const DropDownWithCheckBox = ({
  trigger,
  options,
  onChange,
  error,
  className,
  value,
  loading = false,
  isShowSelectedCount = true,
}: Props) => {
  const [show, setShow] = useState(false);
  const actionsRef = useRef(null);

  const [isSelectAll, setIsSelectAll] = useState(false);

  const [selectedOpt, setSelectedOpt] = useState<number[]>([]);

  useEffect(() => {
    setIsSelectAll(value.length === options.length);
    setSelectedOpt(size(value) > 0 ? value : []);
  }, [value]);

  const onSelect = (option: { name: string; id: number }) => {
    const _selectedOpt: number[] = cloneDeep(selectedOpt);

    const index = findIndex(selectedOpt, (o: any) => o === option.id);

    if (index >= 0) {
      _selectedOpt.splice(index, 1);
    } else {
      _selectedOpt.push(option.id);
    }

    setIsSelectAll(_selectedOpt.length === options.length);
    setSelectedOpt(_selectedOpt);
    onChange(_selectedOpt);
  };

  useOutsideClick(actionsRef, (): void => {
    setShow(false);
  });

  const onSelectAll = () => {
    let _selectedOpt: number[] = cloneDeep(selectedOpt);

    _selectedOpt = [];
    options.forEach((opt) => {
      _selectedOpt.push(opt.id);
    });
    setSelectedOpt(_selectedOpt);
    onChange(_selectedOpt);
  };

  const onUnSelectAll = () => {
    setSelectedOpt([]);
    onChange([]);
    setIsSelectAll(false);
  };

  const getSelectedOptLabel = () => {
    const selectedOptLabels: string[] = [];
    options.forEach((opt) => {
      if (selectedOpt.includes(opt.id)) {
        selectedOptLabels.push(opt.name);
      }
    });
    return selectedOptLabels.join(", ");
  };

  return (
    <div className={className} ref={actionsRef}>
      <div
        className={`container w-[220px] max-[1800px]:w-full ${error ? "" : ""}`}
        onClick={() => setShow((prev) => !prev)}
      >
        <div className="text-sm select-none text-[#1B1C1E] w-full flex items-center gap-x-1">
          <div className="w-full"> {trigger}</div>
          {isShowSelectedCount && size(selectedOpt) > 0 && (
            <div className="flex items-center justify-center h-5 w-5 bg-[#a264df] text-white rounded-full p-1">
              <Tooltip
                className="w-full"
                content={getSelectedOptLabel()}
                placement="top"
              >
                {size(selectedOpt)}
              </Tooltip>
            </div>
          )}
        </div>
        {loading && (
          <svg
            className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75 text-[#A264DF]"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        )}
        <img
          src={allImgPaths.downArrow}
          alt="down-arrow"
          className={`transform transition-all duration-300 select-none ${show ? "rotate-180" : "rotate-0"} `}
        />

        {error && (
          <span className="h-2 w-2 flex absolute top-0 right-0 -mt-1 -mr-1">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
            <span className="relative inline-flex rounded-full h-2 w-2 bg-red-500"></span>
          </span>
        )}
      </div>
      {
        // glass effect dropdown menu shadow-lg backdrop-blur-md !bg-white/30
        show && (
          <div className="menu-list">
            {options.length > 0 ? (
              <>
                <div
                  className="menu flex gap-x-2 w-full cursor-pointer select-none"
                  onClick={() => {
                    if (isSelectAll) {
                      setIsSelectAll(false);
                    } else {
                      setIsSelectAll(true);
                      onSelectAll();
                    }
                  }}
                >
                  <img
                    className="h-3 mt-1"
                    src={
                      isSelectAll
                        ? allImgPaths.greenTick
                        : allImgPaths.blacKTick
                    }
                  />
                  <span className="text-sm">Check All</span>
                </div>
                <div
                  className="menu flex gap-x-2 w-full cursor-pointer select-none"
                  onClick={() => onUnSelectAll()}
                >
                  <img className="h-3 mt-1" src={allImgPaths.cross} />
                  <span className="text-sm">Uncheck All</span>
                </div>
              </>
            ) : (
              <>No record found</>
            )}
            {options.map((opt) => (
              <div
                className="menu flex gap-x-2 w-full cursor-pointer select-none"
                key={opt.name}
              >
                {/* <input type="checkbox" checked={selectedOpt.includes(opt.id)} /> */}
                <Checkbox
                  label={get(opt, "name", "N/A")}
                  name={opt.name}
                  checked={selectedOpt.includes(opt.id)}
                  onChange={() => {
                    onSelect(opt);
                  }}
                />
              </div>
            ))}
          </div>
        )
      }
    </div>
  );
};

export default styled(DropDownWithCheckBox)`
  position: relative;
  height: 30px;
  width: 100%;
  .container {
    position: relative;
    height: 30px;
    border-radius: 12px;
    border: 1px solid transparent;
    color: rgba(255, 255, 255, 0.8);
    min-width: max-content;
    color: #333333;
    padding: 0 16px;
    border-radius: 10px;
    border: 1px solid rgba(15, 0, 64, 0.08);
    background: #f4f4f4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    gap: 10px;

    &.error {
      border-color: rgb(244, 67, 54);
    }
  }
  .menu-list {
    margin: 0;
    z-index: 9999;
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 300px;
    top: 35px;
    padding: 15px;
    max-height: 200px;
    overflow-y: auto;
    gap: 6px;
    border-radius: 0px 0px 5px 5px;
    background: #fff;
    box-shadow:
      0px 0px 0.836px 0px rgba(9, 30, 66, 0.31),
      0px 2.507px 4.178px 0px rgba(9, 30, 66, 0.2);
  }
`;
