import { flatMap, get, has } from "lodash";
import { useReducer } from "react";
import toast from "react-hot-toast";
import api from "../../apis/axiosInterceptor";

interface User {
  userData: object;
  isLoading: boolean;
  totalPageCount: number;
  totalData: number;
  isUserDataLoad: boolean;
  isProfileUpdated: boolean;
  error: string;
}

const initialArgs: User = {
  userData: {},
  totalPageCount: 0,
  totalData: 17,
  isUserDataLoad: false,
  isProfileUpdated: false,
  isLoading: false,
  error: "",
};

const reducer = (state: User, action: User) => {
  return { ...state, ...action };
};

const useUser = () => {
  const [state, setState] = useReducer(reducer, initialArgs);

  const getUsers = async (
    page: string,
    listPerPage: string,
    searchText: string,
  ) => {
    setState({ ...state, isLoading: true });
    try {
      const result: any = await api.get(
        `/api/v1/users/user?page=${page}&listPerPage=${listPerPage}&search=${searchText}`,
      );

      if (result.data.status) {
        setState({
          ...state,
          isLoading: false,
          isUserDataLoad: true,
          totalPageCount: get(result, "data.data.totalPageCount", 0),
          totalData: get(result, "data.data.totalData", 0),
          userData: result.data.data.data,
        });
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        setState({
          ...state,
          isLoading: false,
          isUserDataLoad: false,
          error: errorStr,
        });
        return false;
      }
    } catch (error: any) {
      setState({
        ...state,
        isLoading: false,
        isUserDataLoad: false,
        error: error.message,
      });
      return false;
    }
  };

  const createUser = async (param: any) => {
    console.info("param => ", param);

    const paramObj = {
      ...param,
    };

    let createUserToast;
    try {
      const result: any = await api.post("/api/v1/users/create", paramObj, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (result.data.status) {
        createUserToast = toast.success(result.data.message, {
          id: createUserToast,
        });
        setState({ ...state, userData: result.data.data });
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr, { id: createUserToast });
        setState({ ...state, error: errorStr });
        return false;
      }
    } catch (error: any) {
      toast.error(error.message, { id: createUserToast });
      setState({ ...state, error: error.message });
      return false;
    }
  };

  //Edit
  const editUser = async (userId: string) => {
    try {
      const result: any = await api.get(`/api/v1/users/${userId}`);

      if (result.data.status) {
        return result.data.data;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr);
        setState({ ...state, error: errorStr });
        throw errorStr;
      }
    } catch (error: any) {
      toast.error(error.message);
      setState({ ...state, error: error.message });
      throw error;
    }
  };

  const updateUser = async (userId: string, param: any) => {
    const paramObj = {
      ...param,
      id: userId,
    };
    try {
      const result: any = await api.patch(
        `/api/v1/users/updateprofile`,
        paramObj,
        {
          headers: { "Content-Type": "multipart/form-data" },
        },
      );

      if (result.data.status) {
        toast.success(result.data.message, {
          id: "updateUserToast",
        });
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr, { id: "updateUserToastError" });
        setState({ ...state, error: errorStr });
        return false;
      }
    } catch (error: any) {
      toast.error(error.message, { id: "updateUserToastError" });
      setState({ ...state, error: error.message });
      return false;
    }
  };

  const removeUser = async (id: string) => {
    try {
      const result: any = await api.delete(`/api/v1/users/removeUser?Id=${id}`);

      if (result.data.status) {
        toast.success(result.data.message);
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr);
        setState({ ...state, error: errorStr });

        return false;
      }
    } catch (error: any) {
      toast.error(error.message);
      setState({ ...state, error: error.message });

      return false;
    }
  };

  return {
    getUsers,
    createUser,
    editUser,
    updateUser,
    removeUser,
    ...state,
  };
};

export default useUser;
