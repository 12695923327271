import { get, size } from "lodash";
import { useRef, useState } from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import allImgPaths from "../../assets";
import useOutsideClick from "../../hooks/useOutsideClick";

const DropDownV2 = ({
  trigger,
  options,
  onChange,
  isRequired = false,
  className,
  control,
  name,
  errors,
}: {
  errors?: any;
  name: string;
  control?: any;
  className?: string;
  isRequired?: boolean;
  trigger: any;
  options: { id: number; name: string }[];
  onChange?: (opt: any) => void;
}) => {
  const [show, setShow] = useState(false);
  const actionsRef = useRef(null);

  useOutsideClick(actionsRef, (): void => {
    setShow(false);
  });

  const selectedValue = (id: number | string) => {
    console.log("options =>", options);

    if (size(options)) {
      const obj = options.find((o) => o.id === Number(id));
      return get(obj, "name", trigger);
    }

    return trigger;
  };

  return (
    <div className={className} ref={actionsRef}>
      {control ? (
        <Controller
          render={({ field }) => (
            <div>
              <div
                className={`container ${get(errors, `${name}.message`) ? "error" : ""}`}
                onClick={() => setShow((prev) => !prev)}
              >
                <div className="text-sm select-none text-[#1B1C1E]">
                  {selectedValue(field.value)}{" "}
                  {isRequired && !field.value && (
                    <span className="text-[#D85564]">*</span>
                  )}
                </div>
                <img
                  src={allImgPaths.downArrow}
                  alt="down-arrow"
                  className={`transform transition-all duration-300 select-none ${show ? "rotate-180" : "rotate-0"} `}
                />
              </div>

              {show && (
                <div className="menu-list shadow-lg">
                  {options.map((opt) => (
                    <span
                      className={`menu select-none block w-full cursor-pointer hover:text-[#1c1c2c] duration-200 transition-all ${selectedValue(field.value) === opt.name ? "text-[#1c1c2c] font-bold" : ""}`}
                      key={opt.name}
                      onClick={() => {
                        setShow(false);
                        field.onChange(opt.id);
                        onChange && onChange(opt);
                      }}
                    >
                      {opt.name}
                    </span>
                  ))}
                </div>
              )}
            </div>
          )}
          name={name}
          control={control}
          defaultValue=""
        />
      ) : (
        <>
          <div
            className={`container ${get(errors, `${name}.message`) ? "error" : ""}`}
            onClick={() => setShow((prev) => !prev)}
          >
            <div className="text-sm text-[#1c1c2c]">
              {trigger}{" "}
              {isRequired && <span className="text-[#D85564]">*</span>}
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="10"
              viewBox="0 0 14 10"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.715 2.34715L7.47518 9.28089C7.21252 9.57304 6.78816 9.57304 6.52482 9.28089L0.285008 2.34715C-0.0950026 1.9254 -0.0950026 1.23922 0.285008 0.816723C0.665018 0.394976 1.28177 0.394976 1.66178 0.816723L7.00034 6.74815L12.3375 0.816723C12.7182 0.394976 13.335 0.394976 13.715 0.816723C14.095 1.23922 14.095 1.9254 13.715 2.34715Z"
                fill="#1c1c2c"
              />
            </svg>
          </div>
          {show && (
            <div className="menu-list shadow-lg">
              {options.map((opt) => (
                <span
                  className="menu block w-full cursor-pointer hover:text-[#1c1c2c] transition-all text-sm"
                  key={opt.name}
                  onClick={() => {
                    setShow(false);
                    onChange && onChange(opt);
                  }}
                >
                  {opt.name}
                </span>
              ))}
            </div>
          )}
        </>
      )}
      <div className="label-error-text text-red-500 text-xs">
        {get(errors, `${name}.message`) && (
          <span className="error-message">
            {get(errors, `${name}.message`, "")}
          </span>
        )}
      </div>
    </div>
  );
};

export default styled(DropDownV2)`
  position: relative;
  height: auto;
  margin-top: 10px;
  width: 100%;
  .container {
    position: relative;
    height: 44px;
    border-radius: 12px;
    border: 1px solid transparent;
    color: rgba(255, 255, 255, 0.8);
    width: 100%;
    color: #333333;
    padding: 0 16px;
    border-radius: 10px;
    border: 1px solid rgba(15, 0, 64, 0.08);
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &.error {
      border-color: rgb(244, 67, 54);
    }
  }
  .menu-list {
    margin: 0;
    z-index: 9999;
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    top: 55px;
    border-radius: 5px;
    background: #fff;
    padding: 16px;
    max-height: 200px;
    overflow-y: auto;
    span {
      padding: 5px 0;
    }
  }
`;
