import Breadcrumb from "./Breadcrumb";
import Container from "./Container";
import Header from "./Header";
import Sidebar from "./Sidebar";

const Layout = ({ children }: { children: any }) => {
  return (
    <div className="bg-[#f5f8fa]">
      <div className="flex h-screen">
        <div className="w-[240px] shrink-0 h-screen">
          <Sidebar />
        </div>
        <div className="w-[calc(100%_-_240px)] relative">
          <div className="flex justify-end items-center py-1 bg-white mb-3 relative shadow-lg">
            <Header />
            <div className="absolute inset-x-0 bottom-0 h-1 shadow-sm"></div>
          </div>

          <div className="">
            <div className="gap-4 flex flex-col overflow-y-auto h-[calc(90vh_-_70px)] px-5">
              {children}
            </div>
            <div className="py-2 w-full flex justify-center bg-white absolute bottom-0 left-0 right-0">
              <div>
                Copyright {new Date().getFullYear()} TALKESPORT® All Rights
                reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Breadcrumb, Container };
export default Layout;
