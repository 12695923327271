import pageNotFound from "./icons/404.svg";
import actionEdit from "./icons/action-edit.svg";
import actionRelock from "./icons/action-relock.svg";
import actionRight from "./icons/action-right.svg";
import actionTrash from "./icons/action-trash.svg";
import addLabelQuestion from "./icons/add-label-question.svg";
import advertise from "./icons/ads_icon.png";
import alignJustifyIcon from "./icons/align-justify.svg";
import alignLeftIcon from "./icons/align-left.svg";
import alignRightIcon from "./icons/align-right.svg";
import backIcon from "./icons/back.svg";
import backArrow from "./icons/back_arrow.svg";
import blacKTick from "./icons/black-tick.svg";
import camera from "./icons/camera.svg";
import closeGray from "./icons/close-gray.svg";
import cross from "./icons/cross.svg";
import dashboardDark from "./icons/dashboard-dark.svg";
import dashboardLight from "./icons/dashboard-light.svg";
import dashboard from "./icons/dashboard_icon.png";
import deleteIcon from "./icons/delete.svg";
import downArrow from "./icons/down-arrow.svg";
import downCount from "./icons/down-count.svg";
import editPurpleIcon from "./icons/edit-purple.svg";
import editIcon from "./icons/edit.svg";
import expand from "./icons/expand.svg";
import eyeClose from "./icons/eye-off.svg";
import eyeOpen from "./icons/eye-open.svg";
import filter from "./icons/filter.svg";
import games from "./icons/game_icon.png";
import greenTick from "./icons/green-tick.svg";
import infoIcon from "./icons/info.svg";
import institutesDark from "./icons/institutes-dark.svg";
import institutesLight from "./icons/institutes-light.svg";
import league from "./icons/league_icon.png";
import leftArrow from "./icons/left-arrow.svg";
import loginImage from "./icons/login-image.svg";
import matches from "./icons/matches_icon.png";
import notification from "./icons/notification1.png";
import paperGenerationDark from "./icons/paper-generation-dark.svg";
import paperGenerationLight from "./icons/paper-generation-light.svg";
import paperIcon from "./icons/paper.svg";
import players from "./icons/players_icon.png";
import plusXLIcon from "./icons/plus-xl.svg";
import plusIcon from "./icons/plus.svg";
import plus2Icon from "./icons/plus2.svg";
import profile from "./icons/profile.svg";
import reports from "./icons/reports.svg";
import rightRoundIcon from "./icons/right-arrow-round.svg";
import rightIcon from "./icons/right-arrow.svg";
import searchPurple from "./icons/search-purple.svg";
import search from "./icons/search.svg";
import series from "./icons/series_icon.png";
import teacherDark from "./icons/teachers-dark.svg";
import teacherLight from "./icons/teachers-light.svg";
import teams from "./icons/team_icon.png";
import templates from "./icons/templates.svg";
import tournament from "./icons/tournament_icon.png";
import upCount from "./icons/up-count.svg";
import uploadIcon from "./icons/upload.svg";
import user from "./icons/user.png";
import users from "./icons/User_icon.svg";
import vLine from "./icons/vLine.svg";
import warningIcon from "./icons/warning.svg";
import whiteTick from "./icons/white-tick.svg";
import talesportLogo1 from "./logos/logo1.png";
import talesportLogo3 from "./logos/logo21.png";
import talesportLogo2 from "./logos/logo219.png";

const allImgPaths = (() => ({
  user,
  matches,
  league,
  series,
  tournament,
  advertise,
  profile,
  dashboard,
  games,
  players,
  teams,
  users,
  dashboardLight,
  dashboardDark,
  templates,
  reports,
  institutesLight,
  institutesDark,
  teacherDark,
  teacherLight,
  paperGenerationLight,
  paperGenerationDark,
  rightIcon,
  rightRoundIcon,
  loginImage,
  downArrow,
  actionRight,
  actionEdit,
  actionRelock,
  actionTrash,
  plusIcon,
  plus2Icon,
  plusXLIcon,
  alignLeftIcon,
  alignRightIcon,
  alignJustifyIcon,
  editIcon,
  editPurpleIcon,
  deleteIcon,
  infoIcon,
  blacKTick,
  greenTick,
  cross,
  camera,
  upCount,
  downCount,
  filter,
  search,
  searchPurple,
  backArrow,
  eyeOpen,
  eyeClose,
  vLine,
  expand,
  leftArrow,
  pageNotFound,
  talesportLogo1,
  talesportLogo2,
  warningIcon,
  paperIcon,
  backIcon,
  uploadIcon,
  addLabelQuestion,
  closeGray,
  whiteTick,
  talesportLogo3,
  notification,
}))();
export default allImgPaths;
