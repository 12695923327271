import { cloneDeep, findIndex, get, size } from "lodash";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import allImgPaths from "../../assets";
import { useDebounce } from "../../hooks/useDebounce";
import useOutsideClick from "../../hooks/useOutsideClick";
import Checkbox from "./Checkbox";

interface Props {
  name: string;
  value: any;
  className?: string;
  trigger: any;
  options: any[];
  onChange: (opt: any) => void;
}
const DropDownWithCheckBoxSearch = ({
  trigger,
  options,
  onChange,
  className,
  value,
}: Props) => {
  const [show, setShow] = useState(false);
  const actionsRef = useRef(null);

  const [search, setSearch] = useState("");
  const debouncedSearch: string | number = useDebounce(search, 700);

  const [isSelectAll, setIsSelectAll] = useState(false);

  const [selectedOpt, setSelectedOpt] = useState<number[]>([]);

  const [latestOptions, setLatestOptions] = useState<any[]>([]);

  useEffect(() => {
    //console.log("=> value", value.length, " === ", options.length);

    setIsSelectAll(value.length !== 0 && value.length === options.length);
    setSelectedOpt(size(value) > 0 ? value : []);
  }, [value]);

  //console.log("selectedOpt: ", selectedOpt, " value: ", value);

  useEffect(() => {
    //console.log("options", options);
    const findFilterObj: any = options.filter((obj) =>
      JSON.stringify(obj)
        .toLowerCase()
        .includes(String(debouncedSearch).toLowerCase()),
    );
    //console.log("findFilterObj: ", findFilterObj);
    setLatestOptions(findFilterObj);
  }, [debouncedSearch, options]);

  const onSelect = (option: { name: string; id: number }) => {
    const _selectedOpt: number[] = cloneDeep(selectedOpt);
    //console.log("_selectedOpt ", _selectedOpt);

    const index = findIndex(selectedOpt, (o: any) => o === option.id);

    if (index >= 0) {
      _selectedOpt.splice(index, 1);
    } else {
      _selectedOpt.push(option.id);
    }

    setIsSelectAll(_selectedOpt.length === options.length);
    setSelectedOpt(_selectedOpt);
    onChange(_selectedOpt);
  };

  useOutsideClick(actionsRef, (): void => {
    setShow(false);
  });

  const onSelectAll = () => {
    let _selectedOpt: number[] = cloneDeep(selectedOpt);

    _selectedOpt = [];
    options.forEach((opt) => {
      _selectedOpt.push(opt.id);
    });
    setSelectedOpt(_selectedOpt);
    onChange(_selectedOpt);
  };

  const onUnSelectAll = () => {
    setSelectedOpt([]);
    onChange([]);
    setIsSelectAll(false);
  };

  return (
    <div className={className} ref={actionsRef}>
      <div className="container" onClick={() => setShow((prev) => !prev)}>
        <div className="text-sm select-none text-[#1B1C1E]">{trigger}</div>
        <img
          src={allImgPaths.downArrow}
          alt="down-arrow"
          className={`transform transition-all duration-300 select-none ${show ? "rotate-180" : "rotate-0"} `}
        />
      </div>
      {show && (
        <div className="menu-list">
          <div className="w-full">
            <div className="relative">
              <input
                className="w-full h-8 rounded-[5px] bg-[#f4f4f4] outline-none border-[#1c1c2c] focus:!border-[#1c1c2c] focus:!shadow-none border-[1px] outline-0"
                type="text"
                value={search}
                placeholder="Search"
                onChange={(event) => setSearch(event.target.value)}
              />
              <div className="absolute right-1 top-1/2 transform -translate-x-1/2 -translate-y-1/2 h-5 w-5 items-center justify-center flex">
                <img
                  className="cursor-pointer"
                  src={search ? allImgPaths.cross : allImgPaths.searchPurple}
                  alt="search"
                  onClick={() => search && setSearch("")}
                />
              </div>
            </div>
          </div>
          {options.length > 0 ? (
            <>
              <div
                className="menu flex gap-x-2 w-full cursor-pointer select-none"
                onClick={() => {
                  if (isSelectAll) {
                    setIsSelectAll(false);
                  } else {
                    setIsSelectAll(true);
                    onSelectAll();
                  }
                }}
              >
                <img
                  className="h-3 mt-1"
                  src={
                    isSelectAll ? allImgPaths.greenTick : allImgPaths.blacKTick
                  }
                />
                <span className="text-sm">Check All</span>
              </div>
              <div
                className="menu flex gap-x-2 w-full cursor-pointer select-none"
                onClick={() => onUnSelectAll()}
              >
                <img className="h-3 mt-1" src={allImgPaths.cross} />
                <span className="text-sm">Uncheck All</span>
              </div>
            </>
          ) : (
            <>No record found</>
          )}
          {latestOptions.map((opt) => (
            <div
              className="menu flex gap-x-2 w-full cursor-pointer select-none"
              key={opt.name}
            >
              {/* <input type="checkbox" checked={selectedOpt.includes(opt.id)} /> */}
              <Checkbox
                label={get(opt, "name", "N/A")}
                name={opt.name}
                checked={selectedOpt.includes(opt.id)}
                onChange={() => {
                  onSelect(opt);
                }}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default styled(DropDownWithCheckBoxSearch)`
  position: relative;
  height: 30px;
  width: 100%;
  .container {
    position: relative;
    height: 30px;
    border-radius: 12px;
    border: 1px solid transparent;
    color: rgba(255, 255, 255, 0.8);
    min-width: 170px;
    color: #333333;
    padding: 0 16px;
    border-radius: 10px;
    border: 1px solid rgba(15, 0, 64, 0.08);
    background: #f4f4f4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    gap: 10px;
  }
  .menu-list {
    margin: 0;
    z-index: 9999;
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 250px;
    top: 30px;
    border-radius: 10px;
    background: #f9f5fd;
    padding: 16px;
    max-height: 200px;
    overflow-y: auto;
    gap: 6px;
  }
`;
