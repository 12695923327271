import { yupResolver } from "@hookform/resolvers/yup";
import { get } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import OTPInput from "react-otp-input";
import { sendOtpFormSchema } from "../../validations";
import Button from "../controls/Button";

interface Props {
  forUsername: boolean;
  isShowFooterBtn?: boolean;
  label?: string;
  type?: "text" | "password";
  className?: string;
  value?: string;
  wrongOtp?: boolean;
  otpSendSuccess?: boolean;
  isDisabled?: boolean;
  isOtpDisabled?: boolean;
  info?: React.ReactNode;
  singleNum?: string;
  maxLength?: number;
  min?: string;
  max?: string;
  onSubmit?: (val: string) => void | undefined;
  onSendOtp?: () => void | undefined;
  closeModal?: () => void | undefined;
}

const OtpInput = (props: Props) => {
  const {
    forUsername = true,
    isShowFooterBtn = true,
    wrongOtp = false,
    otpSendSuccess,
    isOtpDisabled,
    isDisabled,
    className,
    value = "",
    onSubmit,
    onSendOtp,
    closeModal,
  } = props;

  const {
    trigger,
    getValues,
    setValue,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      otp: value,
      invalidInput: "",
    },
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(sendOtpFormSchema(forUsername)),
  });

  useEffect(() => {
    setValue("otp", value);
  }, [value]);

  const optTextBoxStyle: object = {
    textAlign: "center",
    width: "3rem",
    fontSize: "16px",
    display: "block",
    boxSizing: "border-box",
    /* width: '100%', */
    height: "100%",
    color: "#333333",
    paddingLeft: "11px",
    borderRadius: "10px",
    border: "1px solid rgba(15, 0, 64, 0.08)",
    background: "#f4f4f4",
    margin: 0,
  };

  const [seconds, setSeconds] = useState(-1);
  const [minutes, setMinutes] = useState(0);
  const [reSendOtp, setReSendOtp] = useState(otpSendSuccess);
  const [resendActive, setResendActive] = useState(false);

  useEffect(() => {
    setReSendOtp(otpSendSuccess);
  }, [otpSendSuccess]);

  useEffect(() => {
    setResendActive(wrongOtp);
  }, [wrongOtp]);

  const onSubmitHandler = useCallback(async () => {
    //console.log("submit: ", isBusy, isDisabled);

    if (isDisabled) {
      return;
    }

    const isOtpValid = await trigger("otp");
    // console.log("isOtpValid: ", isOtpValid);

    if (!isOtpValid) {
      return;
    }

    onSubmit && onSubmit(getValues("otp"));
    setReSendOtp(false);
  }, [onSubmit]);

  const onCloseModal = useCallback(async () => {
    setValue("otp", "");
    closeModal && closeModal();
  }, [closeModal]);

  const sendOtpHandler = useCallback(async () => {
    /*  if (isDisabled) {
      return;
    } */

    onSendOtp && onSendOtp();
    setResendActive(false);
    setReSendOtp(true);
    setMinutes(0);
    setSeconds(30);
  }, [onSendOtp]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(30);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    if (seconds === 0 && minutes === 0) {
      setResendActive(true);
      setReSendOtp(false);
    }

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    if (reSendOtp) {
      setMinutes(0);
      setSeconds(30);
    }
  }, [reSendOtp]);

  console.log("OtpInput reSendOtp: ", reSendOtp);

  return (
    <div className={className}>
      <div>
        <form className="w-full bg-white border rounded-lg border-[#1c1c2c] border-opacity-0 px-3 pt-0 pb-2 mb-2">
          <div>
            <div className="flex gap-2">
              <Controller
                render={({ field }) => (
                  <OTPInput
                    containerStyle="gap-2 pt-5"
                    inputStyle={optTextBoxStyle}
                    value={field.value}
                    onChange={field.onChange}
                    /* {...field} */
                    numInputs={6}
                    inputType="number"
                    renderInput={(props) => (
                      <input {...props} disabled={isOtpDisabled} />
                    )}
                  />
                )}
                name={"otp"}
                control={control}
                defaultValue=""
              />
            </div>
            {get(errors, `otp.message`) && (
              <span className="text-red-500 text-xs">
                {get(errors, `otp.message`, "")}
              </span>
            )}
            <div className="flex flex-row">
              <Button
                className="!p-0"
                variant="text"
                type="button"
                isDisabled={resendActive ? false : true}
                onClick={sendOtpHandler}
              >
                Resend OTP
              </Button>
              {reSendOtp && (
                <div className="flex items-center pl-3">
                  <p className="text-left text-[#1c1c2c]">
                    : {minutes < 10 ? `0${minutes}` : minutes}
                    {seconds < 10 ? `:0${seconds}` : `:${seconds}`}
                  </p>
                </div>
              )}
            </div>
          </div>
          {isShowFooterBtn && (
            <div className="flex items-center justify-center mt-4 gap-7">
              <div className="flex flex-row-reverse justify-end gap-4">
                <Button
                  variant="contained"
                  className="!w-full"
                  isDisabled={isOtpDisabled}
                  type="button"
                  onClick={onSubmitHandler}
                >
                  Submit
                </Button>
                <Button
                  variant="outlined"
                  className="!w-full"
                  type="button"
                  onClick={onCloseModal}
                >
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default OtpInput;
