import { get } from "lodash";
import React from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import { ThemeProps } from "../../types";
interface Props extends ThemeProps {
  label: React.ReactNode | string;
  name: string;
  control?: any;
  errors?: any;
  className?: string;
  value?: string | boolean;
  onChange?: (val: boolean) => void;
  checked?: boolean;
  type?: "primary" | "secondary";
}

const Checkbox = (props: Props) => {
  const {
    label = "",
    name,
    control,
    errors,
    className,
    onChange = () => undefined,
    checked,
    type = "secondary",
  } = props;

  return (
    <div className={className}>
      {control ? (
        <Controller
          render={({ field }) => (
            <div className={`custom-checkbox truncate ${type}`}>
              <input
                type="checkbox"
                id={name}
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
              />
              <label
                htmlFor={name}
                className={`select-none ${get(errors, `${name}.message`) ? "error" : ""}`}
              >
                <div>{label}</div>
              </label>
            </div>
          )}
          name={name}
          control={control}
          defaultValue={false}
        />
      ) : (
        <div className={`custom-checkbox ${type}`}>
          <input
            type={"checkbox"}
            checked={checked}
            name={name}
            id={name}
            onChange={(event: any) => {
              console.log("event =>", event);

              onChange(event.target.checked);
            }}
          />
          <label htmlFor={name} title={`${label}`} className="select-none">
            <div className="truncate w-auto">{label}</div>
          </label>
        </div>
      )}
      <div className="label-error-text">
        {get(errors, `${name}.message`) && (
          <span className="error-message">
            {get(errors, `${name}.message`, "")}
          </span>
        )}
      </div>
    </div>
  );
};

export default styled(Checkbox)(
  () => `
.error-message{
  color: rgb(244, 67, 54);
    font-size: 12px;
  }
`,
);
