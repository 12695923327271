import { flatMap, has } from "lodash";
import { useReducer } from "react";
import toast from "react-hot-toast";
import api from "../../apis/axiosInterceptor";

interface Sync {
  error: string;
  trendingNewList: object;
  categoryData: object;
}

const initialArgs: Sync = {
  error: "",
  trendingNewList: [],
  categoryData: [],
};

const reducer = (state: Sync, action: Sync) => {
  return { ...state, ...action };
};

const useSync = () => {
  const [state, setState] = useReducer(reducer, initialArgs);

  const sendTrandingNotification = async (id: string, message: string) => {
    try {
      const result: any = await api.get(
        `/api/v1/news/sendTrandingNewsNotification?message=${message}&id=${id}`,
      );

      if (result.data.status) {
        setState({
          ...state,
        });
        toast.success(result.data.message);
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr);
        setState({ ...state, error: errorStr });

        return false;
      }
    } catch (error: any) {
      toast.error(error.message);
      setState({ ...state, error: error.message });

      return false;
    }
  };

  const getTrendingNewsList = async () => {
    try {
      const result: any = await api.get(
        `https://www.talkesport.com/wp-json/wp/v2/posts?_embed&page=1&per_page=5&_embed=&_fields=id,date,title,_links.wp:featuredmedia,_embedded.wp:featuredmedia,_links.author,_links.wp:term`,
      );
      console.log(result.data, "--------result---");

      if (result.data.status) {
        setState({ ...state, trendingNewList: result.data });
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        setState({ ...state, error: errorStr });
        return false;
      }
    } catch (error: any) {
      setState({ ...state, error: error.message });
      return false;
    }
  };

  const createCategory = async (param: any) => {
    console.info("param => ", param);

    const paramObj = {
      ...param,
    };

    let createCategoryToast;
    try {
      const result: any = await api.post("/api/v1/news/category", paramObj);
      console.log(result.data);

      if (result.data.status) {
        createCategoryToast = toast.success(result.data.message);
        //toast.dismiss(createCategoryToast);
        setState({ ...state, categoryData: result.data.data });
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr, { id: createCategoryToast });
        setState({ ...state, error: errorStr });
        return false;
      }
    } catch (error: any) {
      toast.error(error.message, { id: createCategoryToast });
      setState({ ...state, error: error.message });
      return false;
    }
  };

  const getCategory = async () => {
    try {
      const result: any = await api.get(`/api/v1/news/getCategory`);

      if (result.data.status) {
        return result.data.data;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr);
        setState({ ...state, error: errorStr });
        throw errorStr;
      }
    } catch (error: any) {
      toast.error(error.message);
      setState({ ...state, error: error.message });
      throw error;
    }
  };

  return {
    sendTrandingNotification,
    getTrendingNewsList,
    createCategory,
    getCategory,
    ...state,
  };
};

export default useSync;
