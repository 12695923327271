import { flatMap, has } from "lodash";
import { useReducer } from "react";
import toast from "react-hot-toast";
import api from "../../apis/axiosInterceptor";

interface Advertise {
  advertiseData: object;
  curAdvertiseEditData: any;
  error: string;
}

const initialArgs: Advertise = {
  advertiseData: {},
  curAdvertiseEditData: {},
  error: "",
};

const reducer = (state: Advertise, action: Advertise) => {
  return { ...state, ...action };
};

const useAdvertise = () => {
  const [state, setState] = useReducer(reducer, initialArgs);

  const getAdvertise = async (
    page: string,
    listPerPage: string,
    searchText: string,
  ) => {
    try {
      const result: any = await api.get(
        `/api/v1/events/advertise?page=${page}&listPerPage=${listPerPage}&search=${searchText}`,
      );

      if (result.data.status) {
        setState({ ...state, advertiseData: result.data.data.data });

        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr);
        setState({ ...state, error: errorStr });

        return false;
      }
    } catch (error: any) {
      toast.error(error.message);
      setState({ ...state, error: error.message });

      return false;
    }
  };

  const createAdvertise = async (param: any) => {
    console.info("param => ", param);

    const paramObj = {
      ...param,
    };

    let createAdvertiseToast;
    try {
      const result: any = await api.post("/api/v1/events/advertise", paramObj, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (result.data.status) {
        createAdvertiseToast = toast.success(result.data.message, {
          id: createAdvertiseToast,
        });
        //toast.dismiss(createAdvertiseToast);
        setState({ ...state, advertiseData: result.data.data });
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr, { id: createAdvertiseToast });
        setState({ ...state, error: errorStr });
        return false;
      }
    } catch (error: any) {
      toast.error(error.message, { id: createAdvertiseToast });
      setState({ ...state, error: error.message });
      return false;
    }
  };

  //Edit
  const editAdvertise = async (adsId: string) => {
    try {
      const result: any = await api.get(`/api/v1/events/advertise/${adsId}`);

      if (result.data.status) {
        setState({
          ...state,
          curAdvertiseEditData: result.data.data,
        });

        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr);
        setState({ ...state, error: errorStr });
        return false;
      }
    } catch (error: any) {
      toast.error(error.message);
      setState({ ...state, error: error.message });
      return false;
    }
  };

  const updateAdvertise = async (adsId: string, param: any) => {
    const paramObj = {
      ...param,
    };
    try {
      paramObj.id = adsId;
      const result: any = await api.patch(
        `/api/v1/events/advertise`,
        paramObj,
        { headers: { "Content-Type": "multipart/form-data" } },
      );

      if (result.data.status) {
        toast.success(result.data.message, {
          id: "updateAdvertiseToast",
        });
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr, { id: "updateAdvertiseToastError" });
        setState({ ...state, error: errorStr });
        return false;
      }
    } catch (error: any) {
      toast.error(error.message, { id: "updateAdvertiseToastError" });
      setState({ ...state, error: error.message });
      return false;
    }
  };

  const deleteAdvertise = async (id: string) => {
    try {
      const result: any = await api.delete(`/api/v1/events/advertise?Id=${id}`);

      if (result.data.status) {
        toast.success("Advertise Deleted has been successfully", {
          id: "deleteAdvertiseToast",
        });
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr);
        setState({ ...state, error: errorStr });

        return false;
      }
    } catch (error: any) {
      console.info("error => ", error);

      toast.error(error.message);
      setState({ ...state, error: error.message });

      return false;
    }
  };

  const removeAdvertise = async (id: string) => {
    try {
      const result: any = await api.delete(`/api/v1/events/advertise?Id=${id}`);

      if (result.data.status) {
        toast.success(result.data.message);
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr);
        setState({ ...state, error: errorStr });

        return false;
      }
    } catch (error: any) {
      toast.error(error.message);
      setState({ ...state, error: error.message });

      return false;
    }
  };

  return {
    updateAdvertise,
    getAdvertise,
    createAdvertise,
    editAdvertise,
    deleteAdvertise,
    removeAdvertise,
    ...state,
  };
};

export default useAdvertise;
