import { yupResolver } from "@hookform/resolvers/yup";
import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import allImgPaths from "../../assets";
import { ChangePasswordFormSchema } from "../../validations";
import Button from "../controls/Button";
import IconButton from "../controls/IconButton";
import TextBox from "../controls/TextBox";

interface Props {
  title?: string;
  label?: string;
  type?: "text" | "password";
  className?: string;
  value?: string;
  isBusy?: boolean;
  info?: React.ReactNode;
  onSubmit?: (val: object) => void | undefined;
  closeModal?: () => void | undefined;
}

const ChangePassword = (props: Props) => {
  const { title = "", isBusy, className, onSubmit, closeModal } = props;

  const {
    handleSubmit,
    setError,
    getValues,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      oldpassword: "",
      newpassword: "",
      confirmPassword: "",
      invalidInput: "",
    },
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(ChangePasswordFormSchema),
  });

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const onSubmitHandler = useCallback(async () => {
    console.log("submit: ", isBusy, setError);
    onSubmit && onSubmit(getValues());
  }, [onSubmit]);

  const onCloseModal = useCallback(async () => {
    closeModal && closeModal();
  }, [closeModal]);

  return (
    <div className={className}>
      <div>
        <p className="text-base pl-4">{title}</p>
        <form
          className="w-full bg-white border rounded-lg border-[#1c1c2c] border-opacity-0 px-3 pt-1 pb-2 mb-2"
          onSubmit={handleSubmit(onSubmitHandler)}
        >
          <div>
            <div className="relative">
              <TextBox
                label="Current Password"
                isRequired={true}
                name="oldpassword"
                type={showCurrentPassword ? "text" : "password"}
                control={control}
                errors={errors}
              />
              <div className="absolute right-[13px] top-[12px] text-sm">
                {
                  <IconButton
                    icon={
                      showCurrentPassword
                        ? allImgPaths.eyeOpen
                        : allImgPaths.eyeClose
                    }
                    type={"button"}
                    className="bg-[#1c1c2c] bg-opacity-0 mb-5"
                    onClick={() => {
                      setShowCurrentPassword(!showCurrentPassword);
                    }}
                  />
                }
              </div>
            </div>
            <div className="relative">
              <TextBox
                label="New Password"
                name="newpassword"
                isRequired={true}
                type={showNewPassword ? "text" : "password"}
                control={control}
                errors={errors}
              />
              <div className="absolute right-[13px] top-[12px] text-sm">
                {
                  <IconButton
                    icon={
                      showNewPassword
                        ? allImgPaths.eyeOpen
                        : allImgPaths.eyeClose
                    }
                    type={"button"}
                    className="bg-[#1c1c2c] bg-opacity-0 mb-5"
                    onClick={() => {
                      setShowNewPassword(!showNewPassword);
                    }}
                  />
                }
              </div>
            </div>
            <div className="relative">
              <TextBox
                label="Confirm Password"
                name="confirmPassword"
                isRequired={true}
                type={showConfirmPassword ? "text" : "password"}
                control={control}
                errors={errors}
              />
              <div className="absolute right-[13px] top-[12px] text-sm">
                {
                  <IconButton
                    icon={
                      showConfirmPassword
                        ? allImgPaths.eyeOpen
                        : allImgPaths.eyeClose
                    }
                    type={"button"}
                    className="bg-[#1c1c2c] bg-opacity-0 mb-5"
                    onClick={() => {
                      setShowConfirmPassword(!showConfirmPassword);
                    }}
                  />
                }
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center mt-6 gap-7">
            <div className="flex flex-row-reverse justify-end gap-4">
              <Button variant="contained" type="submit" className="!w-full">
                Submit
              </Button>
              <Button
                variant="outlined"
                className="!w-full"
                type="button"
                onClick={onCloseModal}
              >
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
