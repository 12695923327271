import { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import allImgPaths from "../../assets";
import { useAppState } from "../../context";
import useOutsideClick from "../../hooks/useOutsideClick";

const Header = () => {
  const { isLoggedIn, userData, logout } = useAppState("auth");
  const [isOpen, setIsOpen] = useState(false);
  const actionsRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      if (logout()) {
        navigate("/login");
      }
    }
  }, [isLoggedIn]);

  useOutsideClick(actionsRef, (): void => {
    isOpen && setIsOpen((prev) => !prev);
  });

  const onLogout = () => {
    if (logout()) {
      navigate("/login");
    }
  };
  return (
    <div className="items-center flex pr-[40px]">
      <div className="flex items-center">
        <div
          className="h-10 w-10 flex items-center cursor-pointer"
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <img
            src={
              userData.profile_pic ? userData.profile_pic : allImgPaths.profile
            }
            alt={"ProfileIcon"}
            className="w-[40px]"
          />
          <svg
            className="-mr-8 h-5 w-5 text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </div>

        <div className="relative inline-block text-left" ref={actionsRef}>
          <div>
            {/* <button
              type="button"
              className="items-center gap-x-1.5 inline-flex justify-center"
              id="menu-button"
              aria-expanded="true"
              aria-haspopup="true"
            >
              <div className="flex flex-col">
                <span
                  id="username"
                  className="text-lg capitalize font-medium tracking-wide"
                >{`${userData.first_name} ${userData.last_name}`}</span>
                <span className="text-xs">{userData.role_name}</span>
              </div>
              <svg
                className="-mr-1 h-5 w-5 text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                  clipRule="evenodd"
                />
              </svg>
            </button> */}
          </div>

          <div>
            <div
              className={`${isOpen ? `block` : `hidden`} absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabIndex={-1}
            >
              <div className="py-1" role="none">
                <NavLink
                  to="/profile"
                  className="text-gray-700 block px-4 py-2 text-sm"
                >
                  Edit Profile
                </NavLink>
                <div className="p-3">
                  <div onClick={() => onLogout()}>
                    <button
                      type="button"
                      className="w-full focus:outline-none text-white bg-[#10B981] font-medium rounded-lg text-sm px-5 py-2.5"
                      role="menuitem"
                      tabIndex={-1}
                      id="menu-item-3"
                    >
                      Logout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
