import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import allImgPaths from "../../assets";
import { IconButton } from "../../components";
import Button from "../../components/controls/Button";
import TextBox from "../../components/controls/TextBox";
import { useAppState } from "../../context";
import { encryptPass } from "../../shared/functions";
import { LoginFormSchema } from "../../validations";

const Login = () => {
  const navigate = useNavigate();
  const { isLoggedIn, isLoading, checkLoginUser } = useAppState("auth");

  const [showPassword, setShowPassword] = useState(false);

  const {
    handleSubmit,
    setError,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
      invalidInput: "",
    },
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(LoginFormSchema),
  });

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/dashboard");
    }
  }, [isLoggedIn]);

  const onSubmit = (data: any) => {
    const password = encryptPass(data.password);

    if (checkLoginUser(data.username, password)) {
      //setLoginUser();
    } else {
      setError("invalidInput", {
        type: "custom",
        message: "Invalid username and password input",
      });
    }
  };

  return (
    <form
      className="w-full bg-white border rounded-lg border-[#1c1c2c] border-opacity-25 px-3 pt-10 pb-4 mb-4"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div>
        <div>
          <TextBox
            label="Email/Mobile No *"
            name="username"
            control={control}
            errors={errors}
          />
        </div>
        <div className="relative">
          <TextBox
            label="Password *"
            name="password"
            type={showPassword ? "text" : "password"}
            min={8}
            max={16}
            control={control}
            errors={errors}
          />
          <div className="absolute right-[13px] top-[12px] text-sm">
            {
              <IconButton
                icon={showPassword ? allImgPaths.eyeOpen : allImgPaths.eyeClose}
                type={"button"}
                className="bg-[#1c1c2c] bg-opacity-0 mb-5"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              />
            }
          </div>
        </div>
        {/* <div className="flex flex-row-reverse">
          <NavLink to="/forgot-password">
            <Button className="!p-0" variant="text" type="button">
              Forgot Password?
            </Button>
          </NavLink>
        </div> */}
      </div>
      <div className="flex items-center justify-center mt-6 gap-7">
        <div className="!w-full">
          <Button variant="contained" type="submit" className="!w-full">
            {isLoading ? "Loading..." : "Login"}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Login;
