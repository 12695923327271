import React from "react";

interface Props {
  children?: React.ReactNode;
  icon: string;
  className?: string;
  isDisabled?: boolean;
  onClick?: () => void | Promise<void | boolean>;
  type?: "button" | "submit";
}

const IconButton = ({
  children,
  icon,
  className = "",
  isDisabled,
  onClick,
  type = "button",
}: Props): React.ReactElement<Props> => {
  return (
    <div
      className={`h-7 w-7 p-1 rounded flex items-center justify-center  ${className}`}
    >
      <button type={type} disabled={isDisabled} onClick={onClick}>
        <div className="flex justify-between">
          {icon && <img src={icon} className="w-full" />}
          <div className="children">{children}</div>
        </div>
      </button>
    </div>
  );
};

export default IconButton;
