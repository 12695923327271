import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import allImgPaths from "../../assets";
import { IconButton } from "../../components";
import Button from "../../components/controls/Button";
import TextBox from "../../components/controls/TextBox";
import { useAppState } from "../../context";
import { encryptPass } from "../../shared/functions";
import { NewPasswordFormSchema } from "../../validations";

const NewPassword = () => {
  const { setOtpVerify, isLoading, changeNewPassword, userName } =
    useAppState("auth");
  const navigate = useNavigate();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const {
    handleSubmit,
    getValues,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      password: "",
      passwordConfirmation: "",
      invalidInput: "",
    },
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(NewPasswordFormSchema),
  });

  const onSubmit = async () => {
    if (
      await changeNewPassword(
        userName,
        encryptPass(getValues().password),
        getValues().passwordConfirmation,
      )
    ) {
      setOtpVerify(false);
      navigate("/");
    }
  };

  return (
    <form
      className="w-full bg-white border rounded-lg border-[#1c1c2c] border-opacity-25 px-3 pt-10 pb-4 mb-4"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div>
        <div className="relative">
          <TextBox
            label="Enter New Password *"
            name="password"
            type={showNewPassword ? "text" : "password"}
            control={control}
            errors={errors}
          />
          <div className="absolute right-[13px] top-[12px] text-sm">
            {
              <IconButton
                icon={
                  showNewPassword ? allImgPaths.eyeOpen : allImgPaths.eyeClose
                }
                type={"button"}
                className="bg-[#1c1c2c] bg-opacity-0 mb-5"
                onClick={() => {
                  setShowNewPassword(!showNewPassword);
                }}
              />
            }
          </div>
        </div>
        <div className="relative">
          <TextBox
            label="Enter Confirm Password *"
            name="passwordConfirmation"
            type={showConfirmPassword ? "text" : "password"}
            control={control}
            errors={errors}
          />
          <div className="absolute right-[13px] top-[12px] text-sm">
            {
              <IconButton
                icon={
                  showConfirmPassword
                    ? allImgPaths.eyeOpen
                    : allImgPaths.eyeClose
                }
                type={"button"}
                className="bg-[#1c1c2c] bg-opacity-0 mb-5"
                onClick={() => {
                  setShowConfirmPassword(!showConfirmPassword);
                }}
              />
            }
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center mt-6 gap-7">
        <div className="!w-full gap-4">
          <Button variant="contained" type="submit" className="!w-full">
            {isLoading ? "Loading..." : "Submit"}
          </Button>
          {/* <Button
            variant="outlined"
            className="!w-full"
            type="button"
            onClick={() => {
              setOtpVerify(false);
              navigate("/login");
            }}
          >
            Cancel
          </Button> */}
          <Button
            variant="text"
            className="!w-full !text-base"
            type="button"
            onClick={() => {
              setOtpVerify(false);
              navigate("/");
            }}
          >
            &lt; Back to login
          </Button>
        </div>
      </div>

      {/* <div className="flex items-center justify-center mt-4 gap-7">
        <div className="flex flex-row-reverse justify-end gap-4">
          <Button
            variant="contained"
            className="!w-full"
            isDisabled={isOtpDisabled}
            type="button"
            onClick={onSubmitHandler}
          >
            Submit
          </Button>
          <Button
            variant="outlined"
            className="!w-full"
            type="button"
            onClick={onCloseModal}
          >
            Cancel
          </Button>
        </div>
      </div> */}
    </form>
  );
};

export default NewPassword;
