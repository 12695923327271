interface Props {
  name: string;
  label: string;
  value?: string;
  checked?: boolean;
  onChange?: (e?: any) => void;
}

const Radio = ({
  name,
  label,
  value,
  onChange = () => undefined,
  checked = false,
}: Props) => {
  return (
    <label className="custom-radio-button flex items-center" htmlFor={name}>
      <input
        id={name}
        name={name}
        type="radio"
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <span className="helping-el cursor-pointer"></span>
      <span className="label-text mt-1">{label}</span>
    </label>
  );
};

export default Radio;
