import { useLayoutEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const Container = ({
  children,
  className,
  onScroll,
}: {
  children: any;
  className?: string;
  onScroll?: (val: number) => void;
}) => {
  const divRef: any = useRef(null);

  useLayoutEffect(() => {
    if (!onScroll) return;

    const handleScroll = () => {
      const scrollPosition = divRef.current.scrollTop;

      // Adjust the value (e.g., 200) to change the scroll threshold
      onScroll && onScroll(scrollPosition);
    };

    // Attach the scroll event listener to the div
    divRef?.current?.addEventListener("scroll", handleScroll);

    // Cleanup the event listener when the component is unmounted
    return () => {
      divRef?.current?.removeEventListener("scroll", handleScroll);
    };
  }, [divRef]);
  const location = useLocation();

  return (
    <div
      className={`bg-white rounded-3xl p-7 w-full ${location.pathname == "/dashboard" || location.pathname == "/sync" || location.pathname == "/notification" ? "h-full" : ""} ${className ? className + " overflow-y-auto " : " "}`}
      ref={divRef}
    >
      {children}
    </div>
  );
};

export default Container;
