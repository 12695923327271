import axios, { AxiosError, AxiosResponse } from "axios";

// Function to refresh the access token
const refreshAccessToken = async () => {
  // Implement logic to refresh the access token, for example, by making a request to a refresh token endpoint
  const refreshToken = sessionStorage.getItem("refreshToken");
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/api/v1/users/refreshToken",
    { refreshToken },
  );
  const newAccessToken = response.data.accessToken;
  sessionStorage.setItem("token", newAccessToken);

  // Retry the original request with the new access token
  return newAccessToken;
};

// Create an Axios instance with a base URL
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Request interceptor
api.interceptors.request.use(
  async (config: any) => {
    // Add authorization header with the access token
    config.headers["Authorization"] =
      `Bearer ${sessionStorage.getItem("token")}`;
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  },
);

// Response interceptor
api.interceptors.response.use(
  (response: AxiosResponse) => {
    // You can do something with the successful response here
    return Promise.resolve(response); //response;
  },
  async (error: any) => {
    const originalRequest = error.config;

    // Check if the error is due to an expired access token
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      try {
        // Attempt to refresh the access token
        const newAccessToken = await refreshAccessToken();

        // Update the original request with the new access token
        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;

        // Retry the original request
        return api(originalRequest);
      } catch (refreshError) {
        // Handle refresh token error (e.g., redirect to login)
        console.error("Refresh token error:", refreshError);
        // You might want to redirect the user to the login page or handle the error in some way
        // Example: history.push('/login');
        return Promise.reject(refreshError);
      }
    }

    // Handle other types of errors
    return Promise.reject(error);
  },
);

export default api;
