import { flatMap, get, has } from "lodash";
import { useReducer } from "react";
import toast from "react-hot-toast";
import api from "../../apis/axiosInterceptor";

interface Tournament {
  tournamentData: object;
  tournamentAllData: object;
  totalPageCount: number;
  totalData: number;
  error: string;
}

const initialArgs: Tournament = {
  tournamentData: {},
  tournamentAllData: {},
  totalPageCount: 0,
  totalData: 17,
  error: "",
};

const reducer = (state: Tournament, action: Tournament) => {
  return { ...state, ...action };
};

const useTournament = () => {
  const [state, setState] = useReducer(reducer, initialArgs);

  const getTournament = async (
    page: string,
    listPerPage: string,
    searchText: string,
  ) => {
    try {
      const result: any = await api.get(
        `/api/v1/events/tournament?page=${page}&listPerPage=${listPerPage}&search=${searchText}`,
      );

      if (result.data.status) {
        setState({
          ...state,
          tournamentData: result.data.data.data,
          totalPageCount: get(result, "data.data.totalPageCount", 0),
          totalData: get(result, "data.data.totalData", 0),
        });

        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr);
        setState({ ...state, error: errorStr });

        return false;
      }
    } catch (error: any) {
      toast.error(error.message);
      setState({ ...state, error: error.message });

      return false;
    }
  };

  const createTournament = async (param: any) => {
    console.info("param => ", param);

    const paramObj = {
      ...param,
    };

    let createTournamentToast;
    try {
      const result: any = await api.post(
        "/api/v1/events/tournament",
        paramObj,
        {
          headers: { "Content-Type": "multipart/form-data" },
        },
      );

      if (result.data.status) {
        createTournamentToast = toast.success(result.data.message, {
          id: createTournamentToast,
        });
        //toast.dismiss(createTournamentToast);
        setState({ ...state, tournamentData: result.data.data });
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr, { id: createTournamentToast });
        setState({ ...state, error: errorStr });
        return false;
      }
    } catch (error: any) {
      toast.error(error.message, { id: createTournamentToast });
      setState({ ...state, error: error.message });
      return false;
    }
  };

  //Edit
  const editTournament = async (teamsId: string) => {
    try {
      const result: any = await api.get(`/api/v1/events/tournament/${teamsId}`);

      if (result.data.status) {
        return result.data.data;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr);
        setState({ ...state, error: errorStr });
        throw errorStr;
      }
    } catch (error: any) {
      toast.error(error.message);
      setState({ ...state, error: error.message });
      throw error;
    }
  };

  const updateTournament = async (teamsId: string, param: any) => {
    const paramObj = {
      ...param,
      id: teamsId,
    };
    try {
      const result: any = await api.patch(
        `/api/v1/events/tournament`,
        paramObj,
        {
          headers: { "Content-Type": "multipart/form-data" },
        },
      );

      if (result.data.status) {
        toast.success(result.data.message, {
          id: "updateTournamentToast",
        });
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr, { id: "updateTournamentToastError" });
        setState({ ...state, error: errorStr });
        return false;
      }
    } catch (error: any) {
      toast.error(error.message, { id: "updateTournamentToastError" });
      setState({ ...state, error: error.message });
      return false;
    }
  };

  const removeTournament = async (id: string) => {
    try {
      const result: any = await api.delete(
        `/api/v1/events/tournament/removeTournament?Id=${id}`,
      );

      if (result.data.status) {
        toast.success(result.data.message);
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr);
        setState({ ...state, error: errorStr });

        return false;
      }
    } catch (error: any) {
      toast.error(error.message);
      setState({ ...state, error: error.message });

      return false;
    }
  };

  const getAllTournament = async () => {
    try {
      const result: any = await api.get(`/api/v1/events/tournament/all`);

      if (result.data.status) {
        setState({ ...state, tournamentAllData: result.data.data });

        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr);
        setState({ ...state, error: errorStr });

        return false;
      }
    } catch (error: any) {
      toast.error(error.message);
      setState({ ...state, error: error.message });

      return false;
    }
  };
  return {
    updateTournament,
    getTournament,
    createTournament,
    editTournament,
    // deletePlayer,
    removeTournament,
    // getCountryList,
    getAllTournament,
    ...state,
  };
};

export default useTournament;
