import { get, size } from "lodash";
import { useRef, useState } from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import allImgPaths from "../../assets";
import useOutsideClick from "../../hooks/useOutsideClick";

const DropDown = ({
  trigger,
  options,
  onChange,
  isRequired = false,
  className,
  control,
  name,
  errors,
  value,
  loading = false,
}: {
  errors?: any;
  value?: any;
  name: string;
  control?: any;
  className?: string;
  isRequired?: boolean;
  trigger: any;
  options: { id: number; name: string }[];
  onChange?: (opt: any) => void;
  loading?: boolean;
}) => {
  const [show, setShow] = useState(false);
  const actionsRef = useRef(null);

  useOutsideClick(actionsRef, (): void => {
    setShow(false);
  });

  const selectedValue = (id: number | string) => {
    //console.log("options =>", options);

    if (size(options)) {
      const obj = options.find((o) => o.id === Number(id));
      return get(obj, "name", trigger);
    }

    return trigger;
  };

  return (
    <div className={className} ref={actionsRef}>
      {control ? (
        <Controller
          render={({ field }) => (
            <div>
              <div
                className={`container ${get(errors, `${name}.message`) ? "error" : ""}`}
                onClick={() => setShow((prev) => !prev)}
              >
                <div className="text-sm select-none text-[#1B1C1E]">
                  {selectedValue(field.value)}
                  {isRequired && !field.value && (
                    <span className="text-[#D85564]">*</span>
                  )}
                </div>

                <div className="flex items-center">
                  {loading && (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75 text-[#A264DF]"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  <img
                    src={allImgPaths.downArrow}
                    alt="down-arrow"
                    className={`transform transition-all duration-300 select-none ${show ? "rotate-180" : "rotate-0"} `}
                  />
                </div>
              </div>

              {show && !loading && (
                <div className="menu-list shadow-lg">
                  {options.map((opt) => (
                    <span
                      className={`menu select-none block w-full cursor-pointer hover:text-[#1c1c2c] duration-200 transition-all ${selectedValue(field.value) === opt.name ? "text-[#1c1c2c] font-bold" : ""}`}
                      key={opt.id}
                      onClick={() => {
                        setShow(false);
                        field.onChange(opt.id);
                        onChange && onChange(opt);
                      }}
                    >
                      {opt.name}
                    </span>
                  ))}
                </div>
              )}
            </div>
          )}
          name={name}
          control={control}
          defaultValue=""
        />
      ) : (
        <>
          <div
            className={`container ${get(errors, `${name}.message`) ? "error" : ""}`}
            onClick={() => setShow((prev) => !prev)}
          >
            <div className="text-sm text-[#3339] truncate">
              {selectedValue(value?.id)}
              {isRequired && !value?.id && (
                <span className="text-[#D85564]">*</span>
              )}
            </div>
            <img src={allImgPaths.downArrow} alt="down-arrow" />
          </div>
          {loading && (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75 text-red-500"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
          {show && (
            <div className="menu-list shadow-lg">
              {options.map((opt) => (
                <span
                  className={`menu select-none block w-full cursor-pointer hover:text-[#1c1c2c] duration-200 transition-all ${selectedValue(value?.id) === opt.name ? "text-[#1c1c2c] font-bold" : ""}`}
                  key={opt.id}
                  onClick={() => {
                    setShow(false);
                    onChange && onChange(opt);
                  }}
                >
                  {opt.name}
                </span>
              ))}
            </div>
          )}
        </>
      )}
      <div className="label-error-text text-red-500 text-xs mt-1">
        {get(errors, `${name}.message`) && (
          <span className="error-message">
            {get(errors, `${name}.message`, "")}
          </span>
        )}
      </div>
    </div>
  );
};

export default styled(DropDown)`
  position: relative;
  height: auto;
  margin-top: 10px;
  width: 100%;
  .container {
    position: relative;
    height: 50px;
    border-radius: 12px;
    border: 1px solid transparent;
    color: rgba(255, 255, 255, 0.8);
    width: 100%;
    color: #333333;
    padding: 0 16px;
    border-radius: 10px;
    border: 1px solid rgba(15, 0, 64, 0.08);
    background: #f4f4f4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .content {
      color: rgba(51, 51, 51, 0.6);
    }

    &.error {
      border-color: rgb(244, 67, 54);
    }
  }
  .menu-list {
    margin: 0;
    z-index: 9999;
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    top: 55px;
    border-radius: 10px;
    background: #f9f5fd;
    padding: 16px;
    max-height: 200px;
    overflow-y: auto;
    gap: 6px;
  }
`;
