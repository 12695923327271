import { lazy } from "react";
import { Navigate, Outlet, useRoutes } from "react-router-dom";

import { toLower } from "lodash";
import { useAppState } from "./context";
import { useBasePath } from "./hooks/useBasePath";
import Auth from "./views/Auth/Index";
import PageNotFound from "./views/PageNotFound";
import Profile from "./views/Profile";

// Pages
const Dashboard = lazy(() => import("./views/Dashboard"));
const GamesCreateUpdate = lazy(() => import("./views/Games/GamesCreateUpdate"));
const Games = lazy(() => import("./views/Games"));
const Advertise = lazy(() => import("./views/Advertise"));
const User = lazy(() => import("./views/User"));
const UserCreateUpdate = lazy(() => import("./views/User/UserCreateUpdate"));
const PagesCreateUpdate = lazy(() => import("./views/Pages/PagesCreateUpdate"));
const Pages = lazy(() => import("./views/Pages"));
const PlayerCreateUpdate = lazy(
  () => import("./views/Player/PlayerCreateUpdate"),
);
const Youtube = lazy(() => import("./views/Youtube"));
const YoutubeCreateUpdate = lazy(
  () => import("./views/Youtube/YoutubeCreateUpdate"),
);
const Player = lazy(() => import("./views/Player"));
const TeamsCreateUpdate = lazy(() => import("./views/Teams/TeamsCreateUpdate"));
const Teams = lazy(() => import("./views/Teams"));
const LeagueCreateUpdate = lazy(
  () => import("./views/League/LeagueCreateUpdate"),
);
const League = lazy(() => import("./views/League"));

const SeriesCreateUpdate = lazy(
  () => import("./views/Series/SeriesCreateUpdate"),
);
const Series = lazy(() => import("./views/Series"));
const TournamentCreateUpdate = lazy(
  () => import("./views/Tournament/TournamentCreateUpdate"),
);
const Tournament = lazy(() => import("./views/Tournament"));

const AdvertiseCreateUpdate = lazy(
  () => import("./views/Advertise/AdvertiseCreateUpdate"),
);
const MatchesCreateUpdate = lazy(
  () => import("./views/Matches/MatchesCreateUpdate"),
);
const Matches = lazy(() => import("./views/Matches"));
const Notification = lazy(() => import("./views/Notification"));
const Sync = lazy(() => import("./views/Sync"));

const UnAuthorizedAccess = lazy(() => import("./views/UnAuthorizedAccess"));
const PrivacyPolicy = lazy(() => import("./views/PrivacyPolicy/index"));
const AccountDeletion = lazy(() => import("./views/AccountDeletion"));
export const PrivateRoute = ({ children }: { children: any }) => {
  const { isLoggedIn } = useAppState("auth");

  return isLoggedIn ? children : <Navigate to="/unauthorized" />;
};
const RouteWrapper = ({ element, role }: { element: any; role: string }) => {
  const basePath = useBasePath();

  const ROLES_ACCESS: any = {
    admin: [
      "/games",
      "/games/create",
      "/games/edit/:id",
      "/advertise",
      "/advertise/create",
      "/advertise/edit/:id",
      "/user",
      "/user/create",
      "/user/edit/:id",
      "/pages",
      "/pages/create",
      "/pages/edit/:id",
      "/player",
      "/player/create",
      "/player/edit/:id",
      "/teams",
      "/teams/create",
      "/teams/edit/:id",
      "/advertise",
      "/advertise/create",
      "/advertise/edit/:id",
      "/league",
      "/league/create",
      "/league/edit/:id",
      "/series",
      "/series/create",
      "/series/edit/:id",
      "/tournament",
      "/tournament/create",
      "/tournament/edit/:id",
      "/matches",
      "/matches/create",
      "/matches/edit/:id",
      "/category",
      "/sync",
      "/youtube",
      "/youtube/create",
      "/youtube/edit/:id",
    ],
  };

  if (!role) {
    return <Navigate to="/login" state={{ from: basePath }} replace={true} />;
  }

  // create function which match role and pathname with RegExp
  const isAuthorized = ROLES_ACCESS[role].some((path: string) => {
    const regExp = new RegExp(path);

    return regExp.test(basePath);
  });

  return isAuthorized ? element : <Navigate to="/unauthorized" />;
};

const Routing = () => {
  const {
    userData: { role_name },
  } = useAppState("auth");

  const routes = useRoutes([
    {
      path: "/",
      element: <Auth type={"1"} />,
    },
    {
      path: "/dashboard",
      element: <Dashboard />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/account-deletion",
      element: <AccountDeletion />,
    },
    {
      path: "/",
      element: <RouteWrapper element={<Outlet />} role={toLower(role_name)} />,
      children: [
        {
          path: "/user",
          element: <User />,
        },
        {
          path: "/user/create",
          element: <UserCreateUpdate type={"1"} />,
          caseSensitive: true,
        },
        {
          path: "/user/edit/:id",
          element: <UserCreateUpdate type={"2"} />,
          caseSensitive: true,
        },
        {
          path: "games",
          element: <Games />,
          caseSensitive: true,
        },
        {
          path: "/games/create",
          element: <GamesCreateUpdate type={"1"} />,
          caseSensitive: true,
        },
        {
          path: "/games/edit/:id",
          element: <GamesCreateUpdate type={"2"} />,
          caseSensitive: true,
        },
        {
          path: "advertise",
          element: <Advertise />,
          caseSensitive: true,
        },
        {
          path: "/advertise/create",
          element: <AdvertiseCreateUpdate type={"1"} />,
          caseSensitive: true,
        },
        {
          path: "/advertise/edit/:id",
          element: <AdvertiseCreateUpdate type={"2"} />,
          caseSensitive: true,
        },
        {
          path: "pages",
          element: <Pages />,
          caseSensitive: true,
        },
        {
          path: "/pages/create",
          element: <PagesCreateUpdate type={"1"} />,
          caseSensitive: true,
        },
        {
          path: "youtube",
          element: <Youtube />,
          caseSensitive: true,
        },
        {
          path: "/youtube/create",
          element: <YoutubeCreateUpdate type={"1"} />,
          caseSensitive: true,
        },
        {
          path: "/youtube/edit/:id",
          element: <YoutubeCreateUpdate type={"2"} />,
          caseSensitive: true,
        },
        {
          path: "/pages/edit/:id",
          element: <PagesCreateUpdate type={"2"} />,
          caseSensitive: true,
        },
        {
          path: "player",
          element: <Player />,
          caseSensitive: true,
        },
        {
          path: "/player/create",
          element: <PlayerCreateUpdate type={"1"} />,
          caseSensitive: true,
        },
        {
          path: "/player/edit/:id",
          element: <PlayerCreateUpdate type={"2"} />,
          caseSensitive: true,
        },
        {
          path: "teams",
          element: <Teams />,
          caseSensitive: true,
        },
        {
          path: "/teams/create",
          element: <TeamsCreateUpdate type={"1"} />,
          caseSensitive: true,
        },
        {
          path: "/teams/edit/:id",
          element: <TeamsCreateUpdate type={"2"} />,
          caseSensitive: true,
        },
        {
          path: "league",
          element: <League />,
          caseSensitive: true,
        },
        {
          path: "/league/create",
          element: <LeagueCreateUpdate type={"1"} />,
          caseSensitive: true,
        },
        {
          path: "/league/edit/:id",
          element: <LeagueCreateUpdate type={"2"} />,
          caseSensitive: true,
        },
        {
          path: "series",
          element: <Series />,
          caseSensitive: true,
        },
        {
          path: "/series/create",
          element: <SeriesCreateUpdate type={"1"} />,
          caseSensitive: true,
        },
        {
          path: "/series/edit/:id",
          element: <SeriesCreateUpdate type={"2"} />,
          caseSensitive: true,
        },
        {
          path: "tournament",
          element: <Tournament />,
          caseSensitive: true,
        },
        {
          path: "/tournament/create",
          element: <TournamentCreateUpdate type={"1"} />,
          caseSensitive: true,
        },
        {
          path: "/tournament/edit/:id",
          element: <TournamentCreateUpdate type={"2"} />,
          caseSensitive: true,
        },
        {
          path: "matches",
          element: <Matches />,
          caseSensitive: true,
        },
        {
          path: "/matches/create",
          element: <MatchesCreateUpdate type={"1"} />,
          caseSensitive: true,
        },
        {
          path: "/matches/edit/:id",
          element: <MatchesCreateUpdate type={"2"} />,
          caseSensitive: true,
        },
        {
          path: "category",
          element: <Notification />,
          caseSensitive: true,
        },
        {
          path: "sync",
          element: <Sync />,
          caseSensitive: true,
        },
      ],
    },
    {
      path: "/profile",
      element: <Profile />,
    },
    {
      path: "/login",
      element: <Auth type={"1"} />,
    },
    {
      path: "/forgot-password",
      element: <Auth type={"2"} />,
    },
    {
      path: "/new-password",
      element: <Auth type={"3"} />,
    },
    {
      path: "unauthorized",
      element: <UnAuthorizedAccess />,
    },
    {
      path: "/*",
      element: <PageNotFound />,
    },
  ]);

  return routes;
};

export default Routing;
