import { get } from "lodash";
import React from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import { ThemeProps } from "../../types";

interface Props extends ThemeProps {
  label?: string;
  singleNum?: string;
  control?: any;
  errors?: any;
  isRequired?: boolean;
  name: string;
  placeholder?: string;
  type?: "text" | "password" | "number" | "date" | "datetime-local";
  maxLength?: number;
  min?: number | string;
  max?: number | string;
  className?: string;
  value?: string;
  info?: React.ReactNode;
  disabled?: boolean;
  onChange?: (val: string) => void | undefined;
}

const TextBox = (props: Props) => {
  const {
    label = "",
    name,
    control,
    errors,
    isRequired,
    min,
    max,
    maxLength,
    disabled,
    singleNum = "",
    className,
    value = "",
    onChange,
    type = "text",
    info,
    placeholder = "",
  } = props;

  const allowOnlyNumber = (value: string) => {
    const val = value.replace(/[^0-9]/g, "");
    // return value.replace(/[^0-9]/g, "");
    // trim val if greathen then 10 digit
    return val.length > parseInt(`${max ?? Infinity}`)
      ? val.substring(0, parseInt(`${max ?? Infinity}`))
      : val;
  };

  return (
    <div className={className}>
      <div
        className={`container ${get(errors, `${name}.message`) ? "error" : ""}`}
      >
        {control ? (
          <Controller
            render={({ field }) => (
              <>
                {type === "number" ? (
                  <input
                    {...field}
                    onChange={(e) => {
                      field.onChange(allowOnlyNumber(e.target.value));
                      onChange && onChange(e.target.value);
                    }}
                    className={
                      singleNum === "true" ? "input-single-number" : ""
                    }
                    min={min}
                    max={max}
                    type={"text"}
                    autoComplete="off"
                    placeholder={placeholder}
                  />
                ) : type === "datetime-local" ? (
                  <input
                    {...field}
                    className={
                      singleNum === "true" ? "input-single-number" : ""
                    }
                    onChange={(e) => field.onChange(e.target.value)}
                    min={min}
                    max={max}
                    type={type}
                    autoComplete="off"
                    placeholder={placeholder}
                  />
                ) : (
                  <input
                    {...field}
                    className={
                      singleNum === "true" ? "input-single-number" : ""
                    }
                    onChange={(e) => {
                      //console.log("first", e, field);
                      field.onChange(e.target.value);
                      onChange && onChange(e.target.value);
                    }}
                    type={type}
                    autoComplete="off"
                    placeholder={placeholder}
                  />
                )}
              </>
            )}
            name={name}
            disabled={disabled}
            control={control}
            defaultValue=""
          />
        ) : (
          <input
            autoComplete="off"
            placeholder={placeholder}
            className={singleNum === "true" ? "input-single-number" : ""}
            maxLength={maxLength}
            min={min}
            max={max}
            name={name}
            value={value}
            defaultValue={value}
            type={type}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange && onChange(e.target.value)
            }
          />
        )}

        <label className="field-placeholder">
          {" "}
          {label}
          {isRequired && <span className="text-[#D85564]">*</span>}{" "}
        </label>
        {info}
      </div>
      {get(errors, `${name}.message`) && (
        <span className="error-message">
          {get(errors, `${name}.message`, "")}
        </span>
      )}
    </div>
  );
};

export default styled(TextBox)(
  ({ label }: Props) => `
height: auto;
margin-top: 10px;
width: 100%;
.container{
  position: relative;
    height: 50px;
    /* padding: 0 12px; */
    border-radius: 12px;
    border: 1px solid transparent;
    /* background: rgba(51,51,51,0.1); */
    color: rgba(255,255,255,0.8);
    width: auto;

  &.error{
    border-color: rgb(244, 67, 54);
  }

  input{
    border: none;
    padding-top: ${label ? "15px" : "0px"};
    font-size: 16px;
    display: block;
    box-sizing: border-box;
    width: 100%;
    bottom: 0px;
    height: 100%;
    color: #1B1C1E;
    padding-left: 16px;
    border-radius: 10px;
    border: 1px solid rgba(15, 0, 64, 0.08);
    background:#f4f4f4;

    &:focus,
    &:not(:placeholder-shown) {
      outline: none;
      /* border-color: transparent; */
      ~ label {
        //Come up with a calculation for this
        top: calc(30% - 12px);
        left: 12px;
        transform: translate(5px, 0%);
        transition: all 0.2s;
        font-weight: 400;
        font-size: 12px;
        color: rgba(51, 51, 51, 0.6);
      }
    }
  }

  label{
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translate(5px, -50%);
    pointer-events: none;
    transition: all 0.14s ease-in-out;
    font-size: 1rem;
    color: rgba(51, 51, 51, 0.6);
  }
}

button{
    font-weight: 400;
    font-size: 12px;
    color: #1c1c2c;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 15px;
    position: absolute;
    right: 0;
    top: 0;
}

.error-message{
    color: rgb(244, 67, 54);
    /* padding-left: 19px; */
    font-size: 12px;
}

.input-single-number{
  padding: 0.5rem !important;
  text-align: center;
  -moz-appearance: textfield;
}

.input-single-number::-webkit-outer-spin-button,
.input-single-number::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background-repeat:  no-repeat;
  width: 17px;
  height: 17px;
  border-width: thin
}
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  position: absolute;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background-repeat:  no-repeat;
  width: 17px;
  height: 17px;
  border-width: thin
}
input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
}
input::-webkit-datetime-edit-fields-wrapper {
  position: relative;
}
input::-webkit-datetime-edit {
  position: relative;
  left: 8%;
}
`,
);
