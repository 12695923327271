import { flatMap, has } from "lodash";
import { useReducer } from "react";
import toast from "react-hot-toast";
import api from "../../apis/axiosInterceptor";
import { setGetLsEncryptDecryptData } from "../../shared/functions";
interface Auth {
  userData: any;
  isLoading: boolean;
  isLoggedIn: boolean;
  otpVerified: boolean;
  isSendOtpMobile: boolean;
  isVerifiedMobileOtp: boolean;
  wrongMobileOtp: boolean;
  isSendOtp: boolean;
  setNewPassword: boolean;
  userId: string;
  userName: string;
  otpData: object;
  otpDataFromMobile: object;
  error: string;
}

const initialArgs: Auth = {
  isLoggedIn:
    sessionStorage.getItem("token") !== "undefined"
      ? Boolean(sessionStorage.getItem("token"))
      : false,
  userData:
    localStorage.getItem("userData") !== "undefined"
      ? setGetLsEncryptDecryptData("get", "userData") === "false"
        ? {}
        : setGetLsEncryptDecryptData("get", "userData")
      : {},
  isLoading: false,
  userId: "",
  userName: "",
  otpData: {},
  otpDataFromMobile: {},
  wrongMobileOtp: false,
  isSendOtpMobile: false,
  isVerifiedMobileOtp: false,
  isSendOtp: false,
  otpVerified: false,
  setNewPassword: false,
  error: "",
};

const reducer = (state: Auth, action: Auth) => {
  return { ...state, ...action };
};

const useAuth = () => {
  const [state, setState] = useReducer(reducer, initialArgs);

  const checkLoginUser = async (username: string, password: string) => {
    const authToast = toast.loading("Loading...");

    try {
      setState({ ...state, isLoading: true });
      const result: any = await api.post("/api/v1/users/login", {
        email: username,
        password: password,
        device_token: "admin",
        device_type: "web",
      });

      if (result.data.status) {
        toast.success("You're successfully logged in", { id: authToast });
        sessionStorage.setItem("token", result.data.token);
        // localStorage.setItem("userData", JSON.stringify(result.data.data));
        setState({
          ...state,
          isLoggedIn: true,
          isLoading: false,
          userData: result.data.data,
        });
        setGetLsEncryptDecryptData(
          "set",
          "userData",
          JSON.stringify(result.data.data),
        );
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr, { id: authToast });
        setState({
          ...state,
          isLoggedIn: false,
          isLoading: false,
          error: errorStr,
        });
        return false;
      }
    } catch (error) {
      toast.error("Invalid username or password", { id: authToast });
      setState({
        ...state,
        isLoggedIn: false,
        isLoading: false,
        error: "Invalid username/password",
      });
      return false;
    }
  };

  /* const sendOtpWithUsername = async(username:string) =>{

    let sendOtpWithUserNameToast;

    try {
      const result:any = await api.post("/api/v1/users/sendOtp",{username:username});

      if(result.data.status){
        sendOtpWithUserNameToast = toast.success(result.data.message,{id: sendOtpWithUserNameToast});
        setState({ ...state, isSendOtp: true, otpData: result.data, userName:username });
        return true;
      }else{
        const errorStr = has(result.data,"errors") ? Object(flatMap(result.data))[1].msg : result.data.message;
        sendOtpWithUserNameToast = toast.error(errorStr,{id: sendOtpWithUserNameToast});
        setState({ ...state, isSendOtp: false, error: errorStr });
        return false;
      }

    } catch (error:any) {
      const hasErrorMsg = has(error,"message");
      //console.log('sendOtpWithUsername  error', hasErrorMsg);
      (hasErrorMsg) ? toast.error(error.message,{id: sendOtpWithUserNameToast}) : toast.error(error.errors[0].msg,{id: sendOtpWithUserNameToast});
      setState({ ...state, isSendOtp: false, error: error.message });
      return false;
    }
  }; */

  const setOtpVerify = async (status: boolean) => {
    setState({
      ...state,
      otpVerified: status,
    });
  };

  const otpVerifyWithUsername = async (username: string, otp: number) => {
    try {
      const result: any = await api.post("/api/v1/users/verifiedOtp", {
        username: username,
        otp: otp,
      });

      if (result.data.status) {
        setState({
          ...state,
          otpVerified: true,
          isSendOtp: false,
          userName: username,
        });
        return true;
      } else {
        toast.error(result.data.message);
        setState({
          ...state,
          otpVerified: false,
          isSendOtp: false,
          error: result.data.message,
        });
        return false;
      }
    } catch (error: any) {
      toast.error(error.message);
      setState({
        ...state,
        otpVerified: false,
        isSendOtp: false,
        error: error.message,
      });
      return false;
    }
  };

  const changeNewPassword = async (username: string, newPassword: string) => {
    try {
      const result: any = await api.post("/api/v1/users/forgetpassword", {
        username: username,
        newpassword: newPassword,
      });

      if (result.data.status) {
        toast.success(result.data.message);
        setState({ ...state, setNewPassword: true });
        return true;
      } else {
        toast.error(result.data.message);
        setState({
          ...state,
          setNewPassword: false,
          error: result.data.message,
        });
        return false;
      }
    } catch (error: any) {
      toast.error(error.message);
      setState({ ...state, setNewPassword: false, error: error.message });
      return false;
    }
  };

  const clearOtpDataFromMobile = async () => {
    setState({
      ...state,
      isSendOtpMobile: false,
      isVerifiedMobileOtp: false,
      wrongMobileOtp: false,
      otpDataFromMobile: {},
    });
  };

  const updateUserData = async () => {
    setGetLsEncryptDecryptData(
      "set",
      "userData",
      JSON.stringify(state.userData),
    );
  };

  const updateStateUserData = () => {
    setState({
      ...state,
      userData:
        setGetLsEncryptDecryptData("get", "userData") === "false"
          ? {}
          : setGetLsEncryptDecryptData("get", "userData"),
    });
    return true;
  };

  const sendOtpWithMobile = async (phone: string, userId: string) => {
    try {
      const result: any = await api.post("/api/v1/users/send/sendMobileOtp", {
        phone: phone,
        userId: userId,
      });
      //console.log("sendOtpWithMobile => ", result.data);

      if (result.data.status) {
        setState({
          ...state,
          isSendOtpMobile: true,
          wrongMobileOtp: false,
          otpDataFromMobile: result.data,
        });
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        setState({
          ...state,
          isSendOtpMobile: false,
          wrongMobileOtp: false,
          error: errorStr,
        });
        return false;
      }
    } catch (error: any) {
      setState({ ...state, isSendOtpMobile: false, error: error.message });
      return false;
    }
  };

  const otpVerifyWithMobile = async (phone: number, otp: number) => {
    let otpVerifyWithUsernameToast;
    try {
      const result: any = await api.post(
        "/api/v1/users/sendMobileOtpVerified",
        { phone: phone, otp: otp, userId: state.userData.id },
      );

      if (result.data.status) {
        otpVerifyWithUsernameToast = toast.success(result.data.message, {
          id: otpVerifyWithUsernameToast,
        });
        setState({
          ...state,
          isVerifiedMobileOtp: true,
          wrongMobileOtp: false,
        });
        return true;
      } else {
        toast.error(result.data.message, { id: otpVerifyWithUsernameToast });
        setState({
          ...state,
          isVerifiedMobileOtp: false,
          wrongMobileOtp: true,
          error: result.data.message,
        });
        return false;
      }
    } catch (error: any) {
      toast.error(error.message, { id: otpVerifyWithUsernameToast });
      setState({ ...state, isVerifiedMobileOtp: false, error: error.message });
      return false;
    }
  };

  const updateState = async (payload: any) => {
    setState({ ...state, ...payload });
  };

  const logout = async () => {
    sessionStorage.clear();
    localStorage.clear();
    setState({ ...state, isLoggedIn: false });

    return true;
  };

  return {
    clearOtpDataFromMobile,
    updateUserData,
    updateStateUserData,
    sendOtpWithMobile,
    changeNewPassword,
    otpVerifyWithUsername,
    setOtpVerify,
    otpVerifyWithMobile,
    checkLoginUser,
    updateState,
    logout,
    ...state,
  };
};

export default useAuth;
