import { flatMap, get, has } from "lodash";
import { useReducer } from "react";
import toast from "react-hot-toast";
import api from "../../apis/axiosInterceptor";

interface Games {
  gamesData: object;
  gamesAllData: object;
  totalPageCount: number;
  totalData: number;
  error: string;
}

const initialArgs: Games = {
  gamesData: {},
  gamesAllData: {},
  totalPageCount: 0,
  totalData: 17,
  error: "",
};

const reducer = (state: Games, action: Games) => {
  return { ...state, ...action };
};

const useGames = () => {
  const [state, setState] = useReducer(reducer, initialArgs);

  const getGames = async (
    page: string,
    listPerPage: string,
    searchText: string,
  ) => {
    try {
      const result: any = await api.get(
        `/api/v1/games?page=${page}&listPerPage=${listPerPage}&search=${searchText}`,
      );

      if (result.data.status) {
        setState({
          ...state,
          gamesData: result.data.data.data,
          totalPageCount: get(result, "data.data.totalPageCount", 0),
          totalData: get(result, "data.data.totalData", 0),
        });

        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr);
        setState({ ...state, error: errorStr });

        return false;
      }
    } catch (error: any) {
      toast.error(error.message);
      setState({ ...state, error: error.message });

      return false;
    }
  };

  const createGames = async (param: any) => {
    console.info("param => ", param);

    const paramObj = {
      ...param,
    };

    let createGamesToast;
    try {
      const result: any = await api.post("/api/v1/games", paramObj, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (result.data.status) {
        createGamesToast = toast.success(result.data.message, {
          id: createGamesToast,
        });
        //toast.dismiss(createGamesToast);
        setState({ ...state, gamesData: result.data.data });
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr, { id: createGamesToast });
        setState({ ...state, error: errorStr });
        return false;
      }
    } catch (error: any) {
      toast.error(error.message, { id: createGamesToast });
      setState({ ...state, error: error.message });
      return false;
    }
  };

  //Edit
  const editGames = async (gameId: string) => {
    try {
      const result: any = await api.get(`/api/v1/games/${gameId}`);

      if (result.data.status) {
        return result.data.data;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr);
        setState({ ...state, error: errorStr });
        throw errorStr;
      }
    } catch (error: any) {
      toast.error(error.message);
      setState({ ...state, error: error.message });
      throw error;
    }
  };

  const updateGames = async (gameId: string, param: any) => {
    const paramObj = {
      ...param,
    };
    try {
      const result: any = await api.patch(`/api/v1/games/${gameId}`, paramObj, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (result.data.status) {
        toast.success(result.data.message, {
          id: "updateGamesToast",
        });
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr, { id: "updateGamesToastError" });
        setState({ ...state, error: errorStr });
        return false;
      }
    } catch (error: any) {
      toast.error(error.message, { id: "updateGamesToastError" });
      setState({ ...state, error: error.message });
      return false;
    }
  };

  const deleteGames = async (userId: string) => {
    try {
      const result: any = await api.delete(`/api/v1/teacher?userId=${userId}`);

      if (result.data.status) {
        toast.success("Teacher Deleted has been successfully", {
          id: "deleteTeacherToast",
        });
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr);
        setState({ ...state, error: errorStr });

        return false;
      }
    } catch (error: any) {
      console.info("error => ", error);

      toast.error(error.message);
      setState({ ...state, error: error.message });

      return false;
    }
  };

  const removeGames = async (id: string) => {
    try {
      const result: any = await api.delete(
        `/api/v1/games/removeGames?Id=${id}`,
      );

      if (result.data.status) {
        toast.success(result.data.message);
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr);
        setState({ ...state, error: errorStr });

        return false;
      }
    } catch (error: any) {
      toast.error(error.message);
      setState({ ...state, error: error.message });

      return false;
    }
  };

  const getAllGames = async () => {
    try {
      const result: any = await api.get(`/api/v1/games/all`);

      if (result.data.status) {
        setState({ ...state, gamesAllData: result.data.data });

        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr);
        setState({ ...state, error: errorStr });

        return false;
      }
    } catch (error: any) {
      toast.error(error.message);
      setState({ ...state, error: error.message });

      return false;
    }
  };
  return {
    updateGames,
    getGames,
    createGames,
    editGames,
    deleteGames,
    removeGames,
    getAllGames,
    ...state,
  };
};

export default useGames;
