import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import App from "./App";
import "./i18n/i18n";
import "./index.css";

import "react-tooltip/dist/react-tooltip.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(<App />);

reportWebVitals();
