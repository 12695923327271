import { Suspense } from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { AppStateProvider } from "./context";

import containers from "./context/state";
import Routing from "./routing";

import { chooseTheme, themes } from "./components";

import "./App.scss";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

function App() {
  const _theme = themes[chooseTheme()];

  return (
    <AppStateProvider containers={containers}>
      <BrowserRouter>
        <Suspense fallback={<div>Loading...</div>}>
          <ThemeProvider theme={_theme}>
            <Routing />
            <Toaster position="top-right" />
          </ThemeProvider>
        </Suspense>
      </BrowserRouter>
    </AppStateProvider>
  );
}

export default App;
