const Breadcrumb = ({ title, children }: { title?: any; children?: any }) => {
  return (
    <div className=" px-7 w-full items-center">
      <div className="flex justify-between">
        {title && (
          <h1 className="text-base xl:text-xl font-bold w-full">{title}</h1>
        )}
        {children}
      </div>
    </div>
  );
};

export default Breadcrumb;
