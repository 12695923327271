import { yupResolver } from "@hookform/resolvers/yup";
import { get, size } from "lodash";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import api from "../../apis/axiosInterceptor";
import Button from "../../components/controls/Button";
import TextBox from "../../components/controls/TextBox";
import { useAppState } from "../../context";
import { otpFormSchema } from "../../validations";

const optTextBoxStyle: object = {
  fontSize: "16px",
  display: "block",
  boxSizing: "border-box",
  width: "100%",
  bottom: "0px",
  height: "100%",
  color: "#333333",
  padding: "8px",
  borderRadius: "10px",
  border: "1px solid rgba(15, 0, 64, 0.08)",
  background: "#f4f4f4",
  margin: 0,
};

const OtpForm = () => {
  /* const { otpVerified, otpVerifyWithUsername, wrongMobileOtp } =
    useAppState("auth"); */
  const [sendOtp, setSendOtp] = useState(false);
  const [reSendOtp, setReSendOtp] = useState(false);
  const [, setReSendOtpBtnActive] = useState(false);
  //const [otp, setOtp] = useState(false);
  const [seconds, setSeconds] = useState(-1);
  const [otpVerified, setOtpVerified] = useState(false);
  const [wrongMobileOtp, setWrongMobileOtp] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [wrongOtp, setWrongOtp] = useState(false);
  const navigate = useNavigate();

  const {
    handleSubmit,
    watch,
    getValues,
    setValue,
    trigger,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      username: "",
      otp: "",
      invalidInput: "",
    },
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(otpFormSchema),
  });

  const { username } = watch();

  const { updateState } = useAppState("auth");

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(30);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    if (seconds === 0 && minutes === 0) {
      setReSendOtpBtnActive(true);
      setReSendOtp(false);
    }

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const onOtpVerify = async () => {
    // OTP Verify will be here
    otpVerifyWithUsername(getValues().username, Number(getValues().otp));
    return;
  };

  useEffect(() => {
    if (otpVerified) {
      setSendOtp(true);
      setWrongOtp(false);
      updateState({ userName: username });
      navigate("/new-password");
    }
  }, [otpVerified]);

  useEffect(() => {
    if (wrongMobileOtp) {
      setWrongOtp(wrongMobileOtp);
    }
  }, [wrongMobileOtp]);

  const sendOtpHandler = async () => {
    setWrongOtp(false);

    if (await trigger("username")) {
      getOtpHandler(username);
    }
  };

  const otpVerifyWithUsername = async (username: string, otp: number) => {
    try {
      const result: any = await api.post("/api/v1/users/verifiedOtp", {
        username: username,
        otp: otp,
      });

      if (result.data.status) {
        setOtpVerified(true);
        setWrongMobileOtp(false);
        setMinutes(0);
        setSeconds(-1);
        setReSendOtp(false);
        return true;
      } else {
        toast.error(result.data.message);
        setOtpVerified(false);
        setWrongMobileOtp(true);
        setMinutes(0);
        setSeconds(-1);
        setReSendOtp(false);
        setWrongOtp(true);
        return false;
      }
    } catch (error: any) {
      toast.error(error.message);
      setOtpVerified(false);
      setWrongMobileOtp(true);
      setMinutes(0);
      setSeconds(-1);
      setReSendOtp(false);
      return false;
    }
  };

  const getOtpHandler = async (userName: string) => {
    if (size(userName)) {
      try {
        const result: any = await api.post("/api/v1/users/sendOtp", {
          username: userName,
        });

        if (result.data.status) {
          setValue("otp", result.data.otp);
          setSendOtp(true);
          setMinutes(0);
          setSeconds(30);
          setReSendOtp(true);
          setReSendOtpBtnActive(false);
          toast.success(result.data.message);
          return true;
        } else {
          toast.error(result.data.message);
          return false;
        }
      } catch (error: any) {
        const errorMsg: string = !error.response.data.status
          ? error.response.data.message
          : error.message;
        toast.error(errorMsg);
        return false;
      }
    }
  };

  return (
    <div>
      {/* <img src={allImgPaths.backArrow} /> */}
      <form
        className="w-full bg-white border rounded-lg border-[#1c1c2c] border-opacity-25 px-3 pt-10 mb-4 pb-1"
        onSubmit={handleSubmit(onOtpVerify)}
      >
        {/* <IconButton
          icon={allImgPaths.backArrow}
          type={"button"}
          className="bg-[#1c1c2c] bg-opacity-25 mb-5"
          onClick={() => {
            navigate("/");
          }}
        /> */}
        <div className="pt-8">
          <div className="flex grid-cols-2 gap-2">
            <TextBox
              className="!m-0"
              label="Username*"
              name="username"
              control={control}
              errors={errors}
            />
            <Button
              className="!w-[10rem]"
              variant="contained"
              type="button"
              onClick={sendOtpHandler}
              isDisabled={sendOtp}
            >
              Send OTP
            </Button>
          </div>
          {sendOtp && (
            <>
              <p className="text-xs pt-3 pl-2">
                An OTP has been send to registered mobile number
              </p>
              <p className="text-xs pl-2">Please verify it below.</p>
            </>
          )}
          <div className="flex gap-2 w-[80%]">
            <Controller
              render={({ field }) => (
                <OTPInput
                  containerStyle="gap-2 pt-5"
                  inputStyle={optTextBoxStyle}
                  value={field.value}
                  onChange={field.onChange}
                  /* {...field} */
                  numInputs={6}
                  inputType="number"
                  renderInput={(props) => (
                    <input {...props} disabled={!sendOtp} />
                  )}
                />
              )}
              name={"otp"}
              control={control}
              defaultValue=""
            />
          </div>
          {get(errors, `otp.message`) && (
            <span className="text-red-500 text-xs">
              {get(errors, `otp.message`, "")}
            </span>
          )}
          <div className="flex flex-row">
            <Button
              className="!p-0"
              variant="text"
              type="button"
              onClick={sendOtpHandler}
              isDisabled={wrongOtp ? false : reSendOtp || seconds === -1}
            >
              Resend OTP
            </Button>
            {reSendOtp && (
              <div className="flex items-center pl-3">
                <p className="text-left text-[#1c1c2c]">
                  : {minutes < 10 ? `0${minutes}` : minutes}
                  {seconds < 10 ? `:0${seconds}` : `:${seconds}`}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center justify-center mt-6 gap-7">
          <div className="w-full">
            <Button
              variant="contained"
              className="!w-full"
              type="submit"
              isDisabled={!sendOtp}
            >
              Verify
            </Button>
            <Button
              variant="text"
              className="!w-full !text-base"
              type="button"
              onClick={() => {
                navigate("/");
              }}
            >
              &lt; Back to login
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default OtpForm;
