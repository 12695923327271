import { flatMap, get, has } from "lodash";
import { useReducer } from "react";
import toast from "react-hot-toast";
import api from "../../apis/axiosInterceptor";

interface League {
  leagueData: object;
  leagueAllData: object;
  totalPageCount: number;
  totalData: number;
  error: string;
  countryList: object;
}

const initialArgs: League = {
  leagueData: {},
  leagueAllData: {},
  totalPageCount: 0,
  totalData: 17,
  error: "",
  countryList: [],
};

const reducer = (state: League, action: League) => {
  return { ...state, ...action };
};

const useLeague = () => {
  const [state, setState] = useReducer(reducer, initialArgs);

  const getLeague = async (
    page: string,
    listPerPage: string,
    searchText: string,
  ) => {
    try {
      const result: any = await api.get(
        `/api/v1/events/league?page=${page}&listPerPage=${listPerPage}&search=${searchText}`,
      );

      if (result.data.status) {
        setState({
          ...state,
          leagueData: result.data.data.data,
          totalPageCount: get(result, "data.data.totalPageCount", 0),
          totalData: get(result, "data.data.totalData", 0),
        });

        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr);
        setState({ ...state, error: errorStr });

        return false;
      }
    } catch (error: any) {
      toast.error(error.message);
      setState({ ...state, error: error.message });

      return false;
    }
  };

  const createLeague = async (param: any) => {
    console.info("param => ", param);

    const paramObj = {
      ...param,
    };

    let createLeagueToast;
    try {
      const result: any = await api.post("/api/v1/events/league", paramObj, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (result.data.status) {
        createLeagueToast = toast.success(result.data.message, {
          id: createLeagueToast,
        });
        //toast.dismiss(createLeagueToast);
        setState({ ...state, leagueData: result.data.data });
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr, { id: createLeagueToast });
        setState({ ...state, error: errorStr });
        return false;
      }
    } catch (error: any) {
      toast.error(error.message, { id: createLeagueToast });
      setState({ ...state, error: error.message });
      return false;
    }
  };

  //Edit
  const editLeague = async (teamsId: string) => {
    try {
      const result: any = await api.get(`/api/v1/events/league/${teamsId}`);

      if (result.data.status) {
        return result.data.data;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr);
        setState({ ...state, error: errorStr });
        throw errorStr;
      }
    } catch (error: any) {
      toast.error(error.message);
      setState({ ...state, error: error.message });
      throw error;
    }
  };

  const updateLeague = async (teamsId: string, param: any) => {
    const paramObj = {
      ...param,
      id: teamsId,
    };
    try {
      const result: any = await api.patch(`/api/v1/events/league`, paramObj, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (result.data.status) {
        toast.success(result.data.message, {
          id: "updateLeagueToast",
        });
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr, { id: "updateLeagueToastError" });
        setState({ ...state, error: errorStr });
        return false;
      }
    } catch (error: any) {
      toast.error(error.message, { id: "updateLeagueToastError" });
      setState({ ...state, error: error.message });
      return false;
    }
  };

  const getCountryList = async () => {
    try {
      const result: any = await api.get(`/api/v1/users/country`);

      if (result.data.status) {
        setState({ ...state, countryList: result.data.data });
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        setState({ ...state, error: errorStr });
        return false;
      }
    } catch (error: any) {
      setState({ ...state, error: error.message });
      return false;
    }
  };

  const removeLeague = async (id: string) => {
    try {
      const result: any = await api.delete(
        `/api/v1/events/removeLeague?Id=${id}`,
      );

      if (result.data.status) {
        toast.success(result.data.message);
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr);
        setState({ ...state, error: errorStr });

        return false;
      }
    } catch (error: any) {
      toast.error(error.message);
      setState({ ...state, error: error.message });

      return false;
    }
  };

  const getAllLeague = async () => {
    try {
      const result: any = await api.get(`/api/v1/events/league/all`);

      if (result.data.status) {
        setState({ ...state, leagueAllData: result.data.data });

        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr);
        setState({ ...state, error: errorStr });

        return false;
      }
    } catch (error: any) {
      toast.error(error.message);
      setState({ ...state, error: error.message });

      return false;
    }
  };
  return {
    updateLeague,
    getLeague,
    createLeague,
    editLeague,
    // deletePlayer,
    removeLeague,
    getCountryList,
    getAllLeague,
    ...state,
  };
};

export default useLeague;
