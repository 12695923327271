import CryptoJS from "crypto-js";
import { size } from "lodash";
import { CONSTANTS } from "./constants";
enum Direction {
  left = "left",
  right = "right",
  justify = "justify",
}

export const getAlignmentClass = (direction: Direction) => {
  switch (direction) {
    case "left":
      return "justify-start";
    case "right":
      return "justify-end";
    case "justify":
      return "justify-center";
    default:
      return "justify-start";
  }
};

export function toShortAddress(
  _address?: string | null | Uint8Array,
  length = 6,
): string {
  const address = (_address || "").toString();

  return address.length > 13
    ? `${address.slice(0, length)}…${address.slice(-length)}`
    : address;
}

export const generateUUID = () => {
  // Public Domain/MIT
  let d = new Date().getTime(); //Timestamp

  let d2 =
    (typeof performance !== "undefined" &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = Math.random() * 16; //random number between 0 and 16

    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }

    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const getDesignation = () => {
  return [
    {
      id: 1,
      code: "chairman",
      name: "Chairman",
      status: 1,
    },
    {
      id: 2,
      code: "trustee",
      name: "Trustee",
      status: 1,
    },
    {
      id: 3,
      code: "principal",
      name: "Principal",
      status: 1,
    },
  ];
};

export const getStatus = () => {
  return [
    /* {
      id: 4,
      name: "Select Subject",
    }, */
    {
      id: 1,
      name: "Active",
    },
    {
      id: 2,
      name: "Inactive",
    },
    {
      id: 0,
      name: "Expiry",
    },
  ];
};

export const getStatusForLicense = () => {
  return [
    /* {
      id: 4,
      name: "Select Status",
    }, */
    {
      id: 1,
      name: "Active",
    },
    {
      id: 2,
      name: "Inactive",
    },
    {
      id: 0,
      name: "Expiry",
    },
  ];
};

export const generateUsername = (
  firstName: string,
  lastName: string,
  charCount: any = 2,
) => {
  const digits = "0123456789";
  let id = firstName.replace(/\s/g, "") + lastName.replace(/\s/g, "");
  //var x = Math.floor((Math.random() * 2) + 1);
  while (charCount--) {
    id += digits[(Math.random() * digits.length) | 0];
  }
  return id;
};

export const findAndUpdate = (
  array: any,
  id: string,
  updateFn: (item: any) => void,
) => {
  for (let i = 0; i < array.length; i++) {
    const item: any = array[i];

    if (item.id === id) {
      // Update the object using the provided update function
      array[i] = updateFn(item);

      return true; // Indicates that the object was found and updated
    }

    if (item.children && item.children.length > 0) {
      // Recursively search in the children array
      const childUpdated = findAndUpdate(item.children, id, updateFn);

      if (childUpdated) {
        return true; // Indicates that the object was found and updated in the children array
      }
    }
  }

  return false; // Indicates that the object with the specified id was not found
};

export const removeById = (arr: any[], targetId: any): any[] => {
  return arr.reduce(
    (acc: any, obj: { id: any; children: any }) =>
      obj.id === targetId
        ? acc
        : [
            ...acc,
            {
              ...obj,
              ...(obj.children && {
                children: removeById(obj.children, targetId),
              }),
            },
          ],
    [],
  );
};

export const checkIfImageExists = (url: any, callback: any) => {
  const img = new Image();
  img.src = url;

  if (img.complete) {
    callback(true);
  } else {
    img.onload = () => {
      callback(true);
    };

    img.onerror = () => {
      callback(false);
    };
  }
};

/* Function use for encrypt password string */
export const encryptPass = (value: string) => {
  console.log(
    CONSTANTS.SECURE_ENC_DEC_KEY,
    "-----CONSTANTS.SECURE_ENC_DEC_KEY--",
  );
  console.log(value, "-----value--");
  return CryptoJS.AES.encrypt(value, CONSTANTS.SECURE_ENC_DEC_KEY).toString();
};

/* Function use for set and get data in localStorage with encrypt/decrypt */
export const setGetLsEncryptDecryptData = (
  type: string = "set",
  key: string,
  value: string = "",
) => {
  if (type === "set") {
    const encData = CryptoJS.AES.encrypt(
      value,
      CONSTANTS.SECURE_ENC_DEC_KEY,
    ).toString();
    localStorage.setItem(key, encData);
    return "true";
  } else if (type === "get") {
    const getEncData = localStorage.getItem(key);
    //console.log("getEncData", getEncData, size(getEncData));

    if (size(getEncData) === 0) {
      return "false";
    }

    const decData = CryptoJS.AES.decrypt(
      String(getEncData),
      CONSTANTS.SECURE_ENC_DEC_KEY,
    );
    let decDataStr = "{}";
    try {
      decDataStr = decData.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.log("decData", error);
      localStorage.removeItem(key);
      return "false";
    }
    let parseDecJson = "";
    try {
      parseDecJson = JSON.parse(decDataStr);
    } catch (error) {
      console.log("error", error);
      localStorage.removeItem(key);
      return "false";
    }
    return parseDecJson;
  } else if (type === "del") {
    localStorage.removeItem(key);
    return "false";
  }
};
