import { NavLink } from "react-router-dom";
import allImgPaths from "../../assets";

const menuItems = [
  { path: "/dashboard", label: "Dashboard", icon: allImgPaths.dashboardLight },
  { path: "/user", label: "User", icon: allImgPaths.teacherLight },
  { path: "/games", label: "Games", icon: allImgPaths.games },
  { path: "/player", label: "Players", icon: allImgPaths.players },
  { path: "/teams", label: "Teams", icon: allImgPaths.teams },
  { path: "/league", label: "League", icon: allImgPaths.league },
  { path: "/series", label: "Series", icon: allImgPaths.series },
  { path: "/tournament", label: "Tournaments", icon: allImgPaths.tournament },
  { path: "/matches", label: "Matches", icon: allImgPaths.matches },
  { path: "/advertise", label: "Advertise", icon: allImgPaths.advertise },
  { path: "/pages", label: "Pages", icon: allImgPaths.institutesLight },
  { path: "/youtube", label: "Youtube", icon: allImgPaths.games },
  {
    path: "/sync",
    label: "Sync & Notification",
    icon: allImgPaths.notification,
  },
  {
    path: "/category",
    label: "Category",
    icon: allImgPaths.paperGenerationLight,
  },
];

const Sidebar = () => {
  return (
    <div className=" bg-[#1e1e2d] h-full relative overflow-y-auto">
      <div className="flex items-center justify-center px-[12px] pt-[60px]">
        <div>
          <div className="flex justify-center">
            <img
              src={allImgPaths.talesportLogo1}
              alt={"ProfileIcon"}
              className="h-15 w-40"
            />
          </div>
        </div>
      </div>
      <div className="mt-[47px]">
        <ul className="gap-1 flex flex-col">
          {menuItems.map((item, index) => (
            <NavLink to={item.path} key={index} className="group pl-3">
              <li className="transition duration-300 group-[&.active]:bg-[#3a3a4f] group-[&.active]:rounded-tl-full text-white group-[&.active]:rounded-bl-full group-[&.active]:text-white text-sm flex gap-[10px] items-center cursor-pointer hover:text-white hover:bg-[#3a3a4f] pl-5 py-3 rounded-tl-full rounded-bl-full">
                <div>
                  <img
                    src={item.icon}
                    alt={item.label}
                    className="h-8 w-8 text-white"
                  />
                </div>
                <div>
                  <span className="group-[&.active]:bg-[#3a3a4f] py-2 px-3 rounded-[3px]">
                    {item.label}
                  </span>
                </div>
              </li>
            </NavLink>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
