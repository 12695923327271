import useAdvertise from "./useAdvertise";
import useAuth from "./useAuth";
import useGames from "./useGames";
import useLeague from "./useLeague";
import useMatches from "./useMatches";
import usePages from "./usePages";
import usePlayer from "./usePlayer";
import useProfile from "./useProfile";
import useSeries from "./useSeries";
import useSync from "./useSync";
import useTeams from "./useTeams";
import useTournament from "./useTournament";
import useUser from "./useUser";
import useYoutube from "./useYoutube";

const containers = {
  auth: useAuth,
  profile: useProfile,
  advertise: useAdvertise,
  games: useGames,
  user: useUser,
  pages: usePages,
  player: usePlayer,
  teams: useTeams,
  league: useLeague,
  series: useSeries,
  tournament: useTournament,
  matches: useMatches,
  sync: useSync,
  youtube: useYoutube,
};

export default containers;
