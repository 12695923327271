import { flatMap, get, has, toLower } from "lodash";
import { useReducer } from "react";
import toast from "react-hot-toast";
import api from "../../apis/axiosInterceptor";

interface Player {
  playerData: object;
  error: string;
  totalPageCount: number;
  totalData: number;
  countryList: object;
}

const initialArgs: Player = {
  playerData: {},
  error: "",
  totalPageCount: 0,
  totalData: 17,
  countryList: [],
};

const reducer = (state: Player, action: Player) => {
  return { ...state, ...action };
};

const usePlayer = () => {
  const [state, setState] = useReducer(reducer, initialArgs);

  const getPlayer = async (
    page: string,
    listPerPage: string,
    searchText: string,
  ) => {
    try {
      const result: any = await api.get(
        `/api/v1/players?page=${page}&listPerPage=${listPerPage}&search=${searchText}&type=web`,
      );

      if (result.data.status) {
        setState({
          ...state,
          playerData: result.data.data.data,
          totalPageCount: get(result, "data.data.totalPageCount", 0),
          totalData: get(result, "data.data.totalData", 0),
        });

        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr);
        setState({ ...state, error: errorStr });

        return false;
      }
    } catch (error: any) {
      toast.error(error.message);
      setState({ ...state, error: error.message });

      return false;
    }
  };

  const createPlayer = async (param: any) => {
    const paramObj = {
      ...param,
    };
    paramObj.status = toLower(paramObj.status) === "active" ? "1" : "0";
    let createPlayerToast;
    try {
      const result: any = await api.post("/api/v1/players", paramObj, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (result.data.status) {
        createPlayerToast = toast.success(result.data.message, {
          id: createPlayerToast,
        });
        //toast.dismiss(createPlayerToast);
        setState({ ...state, playerData: result.data.data });
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr, { id: createPlayerToast });
        setState({ ...state, error: errorStr });
        return false;
      }
    } catch (error: any) {
      toast.error(error.message, { id: createPlayerToast });
      setState({ ...state, error: error.message });
      return false;
    }
  };

  //Edit
  const editPlayer = async (playerId: string) => {
    try {
      const result: any = await api.get(`/api/v1/players/${playerId}`);

      if (result.data.status) {
        return result.data.data;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr);
        setState({ ...state, error: errorStr });
        throw errorStr;
      }
    } catch (error: any) {
      toast.error(error.message);
      setState({ ...state, error: error.message });
      throw error;
    }
  };

  const updatePlayer = async (playerId: string, param: any) => {
    const paramObj = {
      ...param,
    };
    try {
      paramObj.status = toLower(paramObj.status) === "active" ? "1" : "0";
      const result: any = await api.patch(
        `/api/v1/players/${playerId}`,
        paramObj,
        {
          headers: { "Content-Type": "multipart/form-data" },
        },
      );

      if (result.data.status) {
        toast.success(result.data.message, {
          id: "updatePlayerToast",
        });
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr, { id: "updatePlayerToastError" });
        setState({ ...state, error: errorStr });
        return false;
      }
    } catch (error: any) {
      toast.error(error.message, { id: "updatePlayerToastError" });
      setState({ ...state, error: error.message });
      return false;
    }
  };

  const resetPlayerForm = (payload: any) => {
    setState({ ...state, ...payload });
  };
  // const deleteGames = async (userId: string) => {
  //   try {
  //     const result: any = await api.delete(`/api/v1/teacher?userId=${userId}`);

  //     if (result.data.status) {
  //       toast.success("Teacher Deleted has been successfully", {
  //         id: "deleteTeacherToast",
  //       });
  //       return true;
  //     } else {
  //       const errorStr = has(result.data, "errors")
  //         ? Object(flatMap(result.data))[1].msg
  //         : result.data.message;
  //       toast.error(errorStr);
  //       setState({ ...state, error: errorStr });

  //       return false;
  //     }
  //   } catch (error: any) {
  //     console.info("error => ", error);

  //     toast.error(error.message);
  //     setState({ ...state, error: error.message });

  //     return false;
  //   }
  // };

  const getCountryList = async () => {
    try {
      const result: any = await api.get(`/api/v1/users/country`);

      if (result.data.status) {
        setState({ ...state, countryList: result.data.data });
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        setState({ ...state, error: errorStr });
        return false;
      }
    } catch (error: any) {
      setState({ ...state, error: error.message });
      return false;
    }
  };

  const removePlayer = async (id: string) => {
    try {
      const result: any = await api.delete(
        `/api/v1/players/removePlayer?Id=${id}`,
      );

      if (result.data.status) {
        toast.success(result.data.message);
        return true;
      } else {
        const errorStr = has(result.data, "errors")
          ? Object(flatMap(result.data))[1].msg
          : result.data.message;
        toast.error(errorStr);
        setState({ ...state, error: errorStr });

        return false;
      }
    } catch (error: any) {
      toast.error(error.message);
      setState({ ...state, error: error.message });

      return false;
    }
  };

  return {
    updatePlayer,
    getPlayer,
    createPlayer,
    editPlayer,
    // deletePlayer,
    removePlayer,
    getCountryList,
    resetPlayerForm,
    ...state,
  };
};

export default usePlayer;
