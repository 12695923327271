import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from "flowbite-react";
import { get, has, size } from "lodash";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import api from "../../apis/axiosInterceptor";
import allImgPaths from "../../assets";
import { Button, ChangePassword, TextBox } from "../../components";
import Layout, { Breadcrumb, Container } from "../../components/layout";
import { useAppState } from "../../context";
// import { encryptPass } from "../../shared/functions";
import {
  encryptPass,
  setGetLsEncryptDecryptData,
} from "../../shared/functions";
import { profileValidation } from "../../validations";

interface ImageState {
  preview: string;
  data: string | File;
}

const Profile = () => {
  const { otpDataFromMobile, userData, updateStateUserData } =
    useAppState("auth");
  const {
    // getUserProfileData,
    updateUserProfileData,
    changePasswordApi,
  } = useAppState("profile");
  const [openModal, setOpenModal] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [imageRemove, setImageRemove] = useState(false);
  const [image, setImage] = useState<ImageState>({ preview: "", data: "" });
  const [imgPreview, setImgPreview] = useState<any | null>(null);

  const navigate = useNavigate();

  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      profile_pic: "",
      firstName: "",
      lastName: "",
      mobile_no: "",
      email: "",
      password: "12345678",
      country: "1",
      birth_date: "",
      invalidInput: "",
    },
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(profileValidation),
  });

  useEffect(() => {}, [size(otpDataFromMobile)]);

  const fetchUserProfileData = async (id: number) => {
    try {
      const {
        data: { data },
        status,
      } = await api.get(`/api/v1/users/id?id=${id}`);

      if (status === 200) {
        setValue("firstName", get(data, "first_name", ""));
        setValue("lastName", get(data, "last_name", ""));
        setValue("mobile_no", get(data, "mobile_no", ""));
        setValue("email", get(data, "email", ""));
        setValue("password", "xxxxx@xxx");

        if (get(data, "profile_pic", "")) {
          setImgPreview(get(data, "profile_pic", ""));
        } else {
          setImgPreview(allImgPaths.user);
        }
      }
    } catch (error) {
      toast.error("Error while fetching user profile data");
    }
  };

  useEffect(() => {
    fetchUserProfileData(userData.id);
  }, []);

  const onSubmit = async (data: any) => {
    const id = get(userData, "id", "");
    const profilePic = get(userData, "profile_pic", "");

    if (imageRemove && image.data == "") {
      toast.error("Profile Picture is required");
      navigate("/profile");
      return false;
    }

    const resUpdateProfileData = await updateUserProfileData(id, {
      ...data,
      profile_pic: image.data ? image.data : profilePic,
    });

    if (resUpdateProfileData && resUpdateProfileData.status) {
      const userData: any =
        localStorage.getItem("userData") !== "undefined"
          ? setGetLsEncryptDecryptData("get", "userData") === "false"
            ? {}
            : setGetLsEncryptDecryptData("get", "userData")
          : {};

      if (resUpdateProfileData && resUpdateProfileData.profilePic) {
        userData.profile_pic = resUpdateProfileData.profilePic;
      }

      setGetLsEncryptDecryptData("set", "userData", JSON.stringify(userData));
      updateStateUserData();

      navigate("/dashboard");
    } else {
      userData.first_name = data.firstName;
      userData.last_name = data.lastName;
      navigate("/dashboard");
    }
  };

  const clearImage = () => {
    setImgPreview("");
    setImageRemove(true);
  };

  const uploadProfilePic = () => {
    const target = document.getElementById("profilePic") as HTMLInputElement & {
      files: FileList;
    };

    const file = new FileReader();
    file.onload = function () {
      setImgPreview(file.result);
    };
    const img = {
      preview: URL.createObjectURL(target.files[0]),
      data: target.files[0],
    };
    setImage(img);

    try {
      file.readAsDataURL(target.files[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const changePasswordHandler = () => {
    setChangePassword(true);
    setOpenModal(true);
  };

  const changePasswordApiCall = async (data: any) => {
    if (
      await changePasswordApi(
        encryptPass(data.oldpassword),
        encryptPass(data.newpassword),
        encryptPass(data.confirmPassword),
      )
    ) {
      setOpenModal(false);
    }
  };

  const submitNewPasswordHandler = (data: any) => {
    changePasswordApiCall(data);
  };

  const closeModalHandler = () => {
    setOpenModal(false);
  };
  return (
    <Layout>
      <Modal show={openModal} onClose={() => closeModalHandler()} size="xl">
        <Modal.Header className="[&_.ml-auto]:!hidden">
          Change Password
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            {changePassword && (
              <ChangePassword
                title="Please update your password here"
                type="password"
                onSubmit={submitNewPasswordHandler}
                closeModal={closeModalHandler}
              />
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Breadcrumb title="My Profile" />
      <Container>
        <div className="">
          <div className="container mx-auto">
            <div className="flex gap-5 items-center justify-start">
              <div className="rounded-full">
                <div className="flex">
                  <label
                    htmlFor="profileImage"
                    className="relative cursor-pointer"
                  >
                    <img
                      src={imgPreview ? imgPreview : allImgPaths.profile}
                      className="rounded-full h-24 w-24"
                      alt="Upload preview"
                    />
                  </label>
                </div>
              </div>
              <div className="flex flex-col">
                <div>
                  <span>
                    {getValues("firstName")
                      ? getValues("firstName")
                      : userData.first_name}{" "}
                    {getValues("lastName")
                      ? getValues("lastName")
                      : userData.last_name}
                  </span>
                </div>
                <span className="text-[#1B1C1E] text-xs">
                  {has(userData, "role_name") ? userData.role_name : ""}
                </span>
              </div>
            </div>
          </div>
          <div className="my-5">
            <hr />
          </div>
          <div className="container mx-auto">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <TextBox
                    label="First Name"
                    name="firstName"
                    control={control}
                    errors={errors}
                  />
                </div>
                <div>
                  <TextBox
                    label="Last Name"
                    name="lastName"
                    control={control}
                    errors={errors}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div className="relative">
                  <TextBox
                    type="text"
                    label="Mobile number"
                    name="mobile_no"
                    min={10}
                    max={10}
                    control={control}
                    errors={errors}
                  />
                </div>
                <div className="relative">
                  <TextBox
                    type="text"
                    label="Email"
                    name="email"
                    control={control}
                    errors={errors}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div className="relative">
                  <div className="mt-3 w-32 h-24 border rounded-lg flex justify-center items-center">
                    {imgPreview ? (
                      <div className="flex align-middle group gap-4 relative cursor-pointer">
                        <img
                          src={imgPreview}
                          className={`w-32 h-24`}
                          alt="Upload preview"
                        />
                        <div
                          className="absolute right-1.5 top-1.5 bg-[#1c1c2c] p-[6px] rounded-full cursor-pointer hidden group-hover:block"
                          onClick={() => clearImage()}
                        >
                          <img
                            src={allImgPaths.closeGray}
                            className={`w-3 h-3`}
                            alt="Upload preview"
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="flex align-middle gap-4">
                        <label
                          htmlFor="profilePic"
                          className="relative cursor-pointer"
                        >
                          <div className="h-8 w-8 bg-[#1B1C1E] flex items-center justify-center rounded-md">
                            <img
                              src={allImgPaths.camera}
                              className={`h-5 w-5`}
                              alt="Upload preview"
                            />
                          </div>
                          <input
                            id="profilePic"
                            className="hidden"
                            type="file"
                            name="profile_pic"
                            accept="image/*"
                            onChange={uploadProfilePic}
                          />
                        </label>
                      </div>
                    )}
                  </div>
                </div>
                <div className="relative">
                  <TextBox
                    label="Password"
                    name="password"
                    type="password"
                    control={control}
                    errors={errors}
                    disabled={true}
                  />
                  <div className="absolute right-[13px] top-[24px] text-sm">
                    <button
                      type="button"
                      className="text-[#1c1c2c]"
                      onClick={changePasswordHandler}
                    >
                      Change Password
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center mt-20 gap-5">
                <div>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      navigate("/dashboard");
                    }}
                  >
                    Cancel
                  </Button>
                </div>
                <div>
                  <Button variant="contained" type="submit">
                    Save
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default Profile;
